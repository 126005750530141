
<dx-popup [showTitle]="true" [shading]="false" [dragEnabled]="true" [hideOnOutsideClick]="true" [width]="popupWidth"
  height="99%" [visible]="true" (onHiding)="close()" position="left top">
  <!-- [wrapperAttr]="{ id: 'pdfpopup', class: 'pdfpopup' }"> -->
  <dxi-toolbar-item *ngIf="!loading && !isMobile" toolbar="top" location="before" widget="dxButton"
    [options]="{ type: 'default', stylingMode: 'outlined', text: 'Download', onClick: save }">
  </dxi-toolbar-item>
  <dxi-toolbar-item *ngIf="!loading && !isMobile && allowReplace && isInvoiceAdmin" toolbar="top" location="before"
    widget="dxButton"
    [options]="{ type: 'default', stylingMode: 'outlined', text: 'Replace', onClick: replaceInvoice }">
  </dxi-toolbar-item>

  <div *dxTemplate="let data of 'content'">
    <!-- Image Loading Spinner -->
    <js-loading-spinner *ngIf="loading"></js-loading-spinner>

    <!-- Show the PDF -->
    <div *ngIf="!loading && !isMobile && attachmentTypeId !==null">
      <ngx-extended-pdf-viewer *ngIf="attachmentTypeId === attachmentTypeEnum.PDF" [src]="blob"
        [useBrowserLocale]="true" [height]="pdfHeight" [mobileFriendlyZoom]="mobileFriendlyZoomSetting" [zoom]="'auto'"
        [showOpenFileButton]="false" [showSidebarButton]="false" [showDownloadButton]="false"
        [showScrollingButton]="true" [showPrintButton]="false" [showFindButton]="false">
      </ngx-extended-pdf-viewer>

      <img *ngIf="attachmentTypeId === attachmentTypeEnum.JPEG" [src]="'data:image/jpeg;base64,'+blob" />

      <img *ngIf="attachmentTypeId !== attachmentTypeEnum.JPEG && attachmentTypeId !== attachmentTypeEnum.PDF"
        [src]="'data:image/png;base64,'+blob" />
    </div>

    <div *ngIf="attachmentTypeId=== null">NO ATTACHMENT</div>	

    <div *ngIf="!loading && isMobile" class="openButton">
      <button type="button" class="btn btn-sm btn-primary openButton" (click)="openInNewWindow()">
        File ready: tap <b>here</b> to open
        <br>
        Note: Please allow pop-ups in your browser settings
      </button>
    </div>
  </div>
</dx-popup>
<!-- </div> -->

<!-- Replace attachment -->
<dx-popup class="popup" [width]="400" [height]="300" [showTitle]="true" title="Attachments" [shading]="false"
  [dragEnabled]="true" [hideOnOutsideClick]="true" [(visible)]="attachmentsPopupVisible">
  <div *dxTemplate="let data of 'content'">
    <div id="fileuploader-container">
      <dx-file-uploader selectButtonText="Select file" labelText="or drop file here" [multiple]="false" accept="*"
        [(value)]="files" uploadMode="useForm">
      </dx-file-uploader>
    </div>

    <dx-button id="load-button" text="Load" type="success" (onClick)="uploadClick()">
    </dx-button>
  </div>
</dx-popup>


<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [visible]="loadingFile" [showIndicator]="true"
  [showPane]="true" [shading]="false" [hideOnOutsideClick]="false">
</dx-load-panel>
