import { GridService } from './../../services/grid.service';
import { UserService } from './../../services/felixApi/user.service';
import { PurchaseOrder } from '../../dtos/purchase-order';
import { PoService } from '../../services/felixApi/po.service';
import { JobService } from '../../services/felixApi/job.service';
import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Vendor } from '../../dtos/vendor';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import { GlobalService } from '../../services/global.service';
import { InvoiceService } from '../../services/felixApi/invoice.service';
import { NotificationService } from '../../services/notification.service';
import { Job } from '../../dtos/job';
import { saveAs } from 'file-saver';
import { PriceFileItem } from '../../dtos/price-file-item';
import { EstimatingService } from '../../services/felixApi/estimating.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { VendorGroup } from '../../dtos/vendor-group';
import { User } from '../../dtos/user';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'js-order-invoices',
  templateUrl: './order-invoices.component.html',
  styleUrls: ['./order-invoices.component.scss']
})
export class OrderInvoicesComponent implements OnInit, OnDestroy {
  @Input() purchaseOrder: PurchaseOrder;

  subscriptions: Subscription[] = [];
  dataSource: CustomStore;
  vendors: Vendor[];
  loadingData = true;
  jobs: Job[];
  lastVendorId: number;
  attachmentsPopupVisible: boolean;
  currentInvoiceId: any;
  files: any[] = [];
  loadingFile: boolean;
  attachmentExists: any;
  attachmentsPopupHeight: number;
  costCentres: PriceFileItem[];

  @ViewChild('orderInvoices') grid: DxDataGridComponent;
  vendorGroups: VendorGroup[];
  selectedVendorGroupId: number;
  users: User[];

  invoiceStatuses = [
    { id: 1, description: 'Entered' },
    { id: 2, description: 'OnHold' },
    { id: 7, description: 'Approved' },
    { id: 8, description: 'Posted' }
  ];
  selectedVendorId: number;
  selectedJobNumber: string;
  jobNumber = '';

  constructor(
    private globalService: GlobalService,
    private modalService: NgbModal,
    private invoiceService: InvoiceService,
    private notiService: NotificationService,
    private jobService: JobService,
    private poService: PoService,
    private userService: UserService,
    private estimatingService: EstimatingService,
    public gridService: GridService,
    private maintenanceService: MaintenanceService) {
    this.setVendorCellValue = this.setVendorCellValue.bind(this);
    this.downloadAttachment = this.downloadAttachment.bind(this);
    this.calculateVendorSortValue = this.calculateVendorSortValue.bind(this);
  }

  ngOnInit(): void {
    this.loadData(true);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  loadData(useCache: boolean) {
    this.subscriptions.push(
      this.invoiceService.getInvoicesDataForOnHold(useCache)
        .subscribe({
          next: () => {
            this.vendors = this.maintenanceService.allVendors;
            this.jobs = this.jobService.jobs;
            this.costCentres = this.estimatingService.costCentres;
            this.users = this.userService.users;
            this.loadingData = false;
            this.setUpDataSource();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loadingData = false;
          }
        })
    );
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.invoiceService
              .getInvoices(null, null, null, null, null, this.purchaseOrder.id, null, false, true).subscribe({
                next: (res) => {
                  return resolve(res);
                }, error: (err) => {
                  return reject(this.globalService.returnError(err));
                }
              })
          ));
      }
    });
  }

  setVendorCellValue(rowData, value) {
    rowData.vendorId = value;
    this.lastVendorId = value;
  }

  downloadAttachment(e) {
    // download attachment
    this.loadingFile = true;
    this.subscriptions.push(
      this.invoiceService.getInvoiceAttachment(e.row.data.id).subscribe({
        next: (res) => {
          if (res) {
            // saveAs(this.globalService.getBlobFromBase64(res.attachment, res.attachmentTypeId), res.attachmentName);
            this.poService.convertBlobAndOpen(res.attachment, res.attachmentTypeId, res.attachmentName, true, e.row.data.id);
          } else {
            this.poService.convertBlobAndOpen(null, null, null, true, e.row.data.id);
          }
          this.loadingFile = false;
        }, error: (err) => {
          this.notiService.notify(err);
          this.loadingFile = false;
        }
      })
    );
  }

  calculateVendorSortValue(data) {
    return this.vendors.find(i => i.id === data.vendorId)?.vendorName;
  }
}
