import { Observable, of, throwError as observableThrowError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { GlobalService } from '../global.service';
import { HttpService } from '../http.service';
import { DocumentFolder } from '../../dtos/document-folder';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  baseFolders: DocumentFolder[];
  baseFoldersCompany: number;
  documentFolders: DocumentFolder[];
  documentFoldersCompany: number;

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService) { }

  getSharePointDocTemplates(folderName, isSharePoint: boolean): Observable<DocumentFolder[]> {
    const url = this.globalService.getApiUrl() + '/' + (isSharePoint ? 'sharepoint' : 'google-drive') + '/get-templates'
      + '?folderName=' + encodeURIComponent(folderName);

    return this.http.get<DocumentFolder[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  getDocumentFolders(useCache: boolean = true): Observable<DocumentFolder[]> {
    if (useCache && this.documentFoldersCompany === this.globalService.getCurrentCompany().id
      && this.documentFolders && this.documentFolders.length) {
      return of(this.documentFolders);
    } else {
      const url = this.globalService.getApiUrl() + '/document-folders/';
      return this.http.get<DocumentFolder[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.documentFolders = res;
          this.documentFoldersCompany = this.globalService.getCurrentCompany().id;
        }),
        catchError(this.handleError));
    }
  }


  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
