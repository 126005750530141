<div class="modal-header">
  <h3 class="modal-title">Select Merge Template</h3>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div style="text-align: right; margin-right: 20px;">
  <dx-check-box id="cbCreatePDF" text="Create PDF" (onValueChanged)="createPDFChanged($event)" [value]="true" style="margin-right: 10px;"></dx-check-box>
  <dx-check-box id="cbAutoDownload" text="Auto Download" (onValueChanged)="autoDownloadChanged($event)" [value]="true"></dx-check-box>
</div>
<div class="modal-body">
<div class="card p-2">
<dx-list id="grid1" class="m-auto templates-height" selectionMode="single" [dataSource]="dataSource2" displayExpr="name" keyExpr="id" height="400" (onSelectionChanged)="selectionChanged($event)">
</dx-list>
    
<form (submit)="selectTemplate()">
  <dx-form id="form" [(formData)]="selectMergeTemplateForm" [readOnly]="false" [showColonAfterLabel]="true"
    labelLocation="left" width="100%" [showValidationSummary]="false" validationGroup="customerData">
  <dxi-item itemType="button" [colSpan]="10" horizontalAlignment="right" [buttonOptions]="selectTemplateButtonOptions">
  </dxi-item>
</dx-form>