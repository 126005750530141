import { AuthService } from './../../services/auth.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Invoice, BackChargeForm, InvoiceStatusTypeEnum } from '../../dtos/invoice';
import { InvoiceService } from '../../services/felixApi/invoice.service';
import { JobService } from '../../services/felixApi/job.service';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import { NotificationService } from '../../services/notification.service';
import { Job } from '../../dtos/job';
import { Vendor } from '../../dtos/vendor';

@Component({
  selector: 'js-back-charge',
  templateUrl: './back-charge.component.html',
  styleUrls: ['./back-charge.component.scss']
})
export class BackChargeComponent implements OnInit, OnDestroy {
  @Input() invoice: Invoice;

  subscriptions: Subscription[] = [];
  loading = true;
  backChargeForm: BackChargeForm = new BackChargeForm();
  invoiceDateOptions: object;
  updateButtonOptions = {
    text: 'BackCharge',
    type: 'default',
    stylingMode: 'contained',
    useSubmitBehavior: true
  };
  job: Job;
  vendors: Vendor[];
  vendorOptions: object;
  invoiceVendorOptions: object;
  invoiceTotalExOptions: object;

  constructor(
    private _activeModal: NgbActiveModal,
    private maintenanceService: MaintenanceService,
    private jobService: JobService,
    private notiService: NotificationService,
    private invoiceService: InvoiceService,
    private authService: AuthService
  ) {  }

  ngOnInit() {
    this.vendors = this.maintenanceService.allVendors;
    this.job = this.jobService.jobs.find(i => i.id === this.invoice.jobId);

    this.backChargeForm.invoicedVendor = this.vendors.find(i => i.id === this.invoice.vendorId)?.vendorName;
    this.backChargeForm.jobNumber = this.job?.jobNumber;
    this.backChargeForm.jobAddressString = this.job?.jobAddressString;
    this.backChargeForm.invoiceNumber = this.job?.jobNumber + '.' + this.invoice.poNumber + '-BC';
    this.backChargeForm.invoiceDate = new Date;
    this.backChargeForm.emailSubject = `Notice of Back-Charge for job ${this.job?.jobNumber} at ${this.job?.jobAddressString}`;
    this.backChargeForm.comment = '';
    this.backChargeForm.ccToSelf = true;
    this.backChargeForm.totalExGST = this.invoice.totalExGST;
    this.backChargeForm.totalGST = this.invoice.totalGST;
    this.backChargeForm.totalIncGST = this.invoice.totalIncGST;

    this.invoiceVendorOptions = {
      items: this.vendors,
      required: true,
      displayExpr: 'vendorName',
      valueExpr: 'id'
    };

    this.vendorOptions = {
      items: this.vendors,
      required: true,
      displayExpr: 'vendorName',
      valueExpr: 'id',
      searchEnabled: true,
      onValueChanged: (e) => {
        this.vendorChanged(e.value);
      }
    };

    this.invoiceDateOptions = {
      type: 'date',
      useMaskBehavior: true,
      disabled: true
    };

    this.invoiceTotalExOptions = {
      type: 'number',
      format: '#,##0.00',
      min: 0,
      onValueChanged: (e) => {
        this.invoiceTotalExChanged(e.value);
      }
    };

    this.loading = false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  cancel() {
    this._activeModal.dismiss();
  }

  reject() {
    this.loading = true;
    const invoiceData = new Invoice(this.backChargeForm.vendorId,
      this.backChargeForm.invoiceNumber, this.backChargeForm.invoiceDate,
      this.job.jobNumber + '.' + this.invoice.poNumber, this.backChargeForm.comment,
      -this.backChargeForm.totalExGST, -this.backChargeForm.totalGST, -this.backChargeForm.totalIncGST,
      this.invoice.purchaseOrderId, this.invoice.invoiceBatchId, true, this.authService.getCurrentUserId(),
      new Date(), InvoiceStatusTypeEnum.Approved,
      this.backChargeForm.sendToEmail, this.backChargeForm.ccToSelf, this.backChargeForm.emailSubject);

    this.subscriptions.push(
      this.invoiceService.backChargeInvoice(this.invoice.id, invoiceData)
        .subscribe({
          next: () => {
            this.notiService.showSuccess('Invoice Back-Charged');
            this._activeModal.close();
          },
          error: (err) => {
            this.loading = false;
            this.notiService.notify(err);
          }
        })
    );
  }

  vendorChanged(value: any) {
    this.backChargeForm.vendorId = value;
    const vendor = this.vendors.find(i => i.id === value);
    this.backChargeForm.sendToEmail = vendor?.email;
  }

  invoiceTotalExChanged(value: any) {
    this.backChargeForm.totalExGST = value;
    this.backChargeForm.totalIncGST = value * 1.1;
    this.backChargeForm.totalGST = value * 0.1; // these are recalculated to ensure they are correct in the API
  }
}
