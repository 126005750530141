import { AuthService } from './../../services/auth.service';
import { JobService } from './../../services/felixApi/job.service';
import { EstimatingService } from './../../services/felixApi/estimating.service';
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { VarianceCode } from '../../dtos/variance-code';
import { VarianceReason } from '../../dtos/variance-reason';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import { Subscription } from 'rxjs';
import { PoService } from '../../services/felixApi/po.service';
import { NotificationService } from '../../services/notification.service';
import { OrderLineExtraForm } from '../../dtos/order-line-extra';
import { PurchaseOrder } from '../../dtos/purchase-order';
import { PriceFileItem } from '../../dtos/price-file-item';
import { Vendor } from '../../dtos/vendor';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateZeroPoComponent } from '../create-zero-po/create-zero-po.component';
import { OrderLookup } from '../../dtos/order-lookup';

@Component({
  selector: 'js-extra-select',
  templateUrl: './extra-select.component.html',
  styleUrls: ['./extra-select.component.scss']
})
export class ExtraSelectComponent implements OnInit, OnDestroy {
  @Input() purchaseOrderId: number;
  @Input() poNumber: string;
  @Input() defaultDescription: string;
  @Input() jobId: number;

  @Output() popupClose: EventEmitter<object> =
    new EventEmitter<object>();

  loading = true;
  subscriptions: Subscription[] = [];
  varianceCodes: VarianceCode[];
  varianceReasons: VarianceReason[];
  adHocReason: VarianceReason;
  orderLineExtraForm: OrderLineExtraForm;
  title: string;
  purchaseOrders: PurchaseOrder[];
  dropDownOptionsPOs: { width: number; minHeight: number; };
  costCentres: PriceFileItem[];
  vendors: Vendor[];
  doNotAllowCosts: boolean;
  popupVisible: boolean;
  allowNonExtra = false;
  isAddingAsExtra = true;
  orderHeaderId: number;
  costCentreId: number;
  popupHeight = 350;
  vendorId: number;

  constructor(
    private maintenanceService: MaintenanceService,
    private notiService: NotificationService,
    private poService: PoService,
    private estimatingService: EstimatingService,
    private modalService: NgbModal,
    private jobService: JobService,
    private authService: AuthService
  ) {
    this.cancel = this.cancel.bind(this);
    this.onLookupValueChanged = this.onLookupValueChanged.bind(this);
    this.addCustomItem = this.addCustomItem.bind(this);
    this.onPurchaseOrderSelectionChanged = this.onPurchaseOrderSelectionChanged.bind(this);
  }

  ngOnInit() {
    this.popupVisible = true;
    this.title = 'Enter extra for order ' + this.poNumber;
    this.dropDownOptionsPOs = { width: 700, minHeight: 400 };
    this.getOrderHeader();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getOrderHeader() {
    this.allowNonExtra = false;

    const poPerm = this.authService.areaPermissions.find(i => i.applicationArea === 'PurchaseOrders');
    if (this.authService.isAdminOrSuper() || poPerm?.permissionType === 'Admin') {
      this.subscriptions.push(
        this.poService.getOrderHeader(this.jobId)
          .subscribe(
            orderHeader => {
              this.allowNonExtra = !(orderHeader?.isLocked ?? false);
              this.orderHeaderId = orderHeader?.id;
              this.loadData();
            },
            err => {
              this.notiService.notify(err);
              this.loading = false;
            })
      );
    } else {
      this.loadData();
    }
  }

  loadData() {
    this.subscriptions.push(
      this.poService.getExtrasData()
        .subscribe(
          () => {
            this.varianceCodes = this.maintenanceService.varianceCodes;
            this.varianceReasons = this.maintenanceService.varianceReasons;
            this.purchaseOrders = this.poService.allPurchaseOrdersForCompany.filter(i => i.jobId === this.jobId);

            this.purchaseOrders.forEach(po => {
              po.remainingAmount = po.orderTotal - (po.approvedInvoicesTotal ?? 0);
            });

            this.costCentres = this.estimatingService.costCentres;
            this.vendors = this.maintenanceService.allVendors;
            this.orderLineExtraForm = {
              varianceCodeId: null,
              varianceReasonDescription: null,
              orderLineDescription: this.defaultDescription,
              varianceAmount: null,
              fromPurchaseOrderId: null
            };

            const thisPO = this.poService.allPurchaseOrdersForCompany.find(i => i.id === this.purchaseOrderId);
            const costCentre = this.costCentres.find(i => i.id === thisPO?.costCentreId);
            this.costCentreId = costCentre?.id;
            this.vendorId = thisPO?.vendorId;

            if (costCentre?.doNotAllowCosts) {
              this.doNotAllowCosts = true;
            }

            this.loading = false;
          },
          err => {
            this.notiService.notify(err);
            this.loading = false;
          })
    );
  }

  OpenSelectBox(e: any): void {
    const needOpen =
      !e.component._popup || e.component._popup.option('visible') === false;
    if (needOpen) {
      setTimeout(() => e.component.open(), 200);
    }
  }

  addCustomItem(data) {
    if (!data.text) {
      data.customItem = null;
      return;
    }

    this.addToList(data.text);

    this.orderLineExtraForm.varianceReasonDescription = data.text;
    return this.adHocReason;
  }

  addToList(adhocText: string) {
    const productIds = this.varianceReasons.map(function (item) {
      return item.id;
    });
    const incrementedId = Math.max.apply(null, productIds) + 1;

    this.adHocReason = new VarianceReason(incrementedId, adhocText);
    this.varianceReasons.push(this.adHocReason);
  }

  onLookupValueChanged(ea) {
    if (!this.adHocReason) {
      this.orderLineExtraForm.varianceReasonDescription = ea.value;
    } else {
      this.orderLineExtraForm.varianceReasonDescription = this.adHocReason.reason;
      this.adHocReason = null;
    }
  }

  cancel() {
    this.popupClose.emit(new OrderLookup(0, 0));
  }

  submit() {
    if (this.isAddingAsExtra) {
      if (this.orderLineExtraForm.varianceCodeId && this.orderLineExtraForm.varianceReasonDescription?.length
        && this.orderLineExtraForm.orderLineDescription?.length && this.orderLineExtraForm.varianceAmount) {
        this.loading = true;
        const values = {
          purchaseOrderId: this.purchaseOrderId,
          description: this.orderLineExtraForm.orderLineDescription,
          rate: this.orderLineExtraForm.varianceAmount,
          varianceCodeId: this.orderLineExtraForm.varianceCodeId,
          varianceReason: this.orderLineExtraForm.varianceReasonDescription
        };

        this.subscriptions.push(
          this.poService.addOrderLineWithExtra(values)
            .subscribe(
              () => {
                this.updatePOInMemory();
              },
              err => {
                this.notiService.notify(err);
                this.loading = false;
              })
        );
      }
    } else {
      if (this.orderLineExtraForm.orderLineDescription?.length && this.orderLineExtraForm.varianceAmount) {
        this.loading = true;

        const values = {
          orderHeaderId: this.orderHeaderId,
          costCentreId: this.costCentreId,
          vendorId: this.vendorId,
          hasBlob: false,
          isDeleted: false,
          quantityString: '1',
          measuredQuantity: 1,
          supplierQuantity: 1,
          purchaseOrderId: this.purchaseOrderId,
          description: this.orderLineExtraForm.orderLineDescription,
          rate: this.orderLineExtraForm.varianceAmount,
          lineTotal: this.orderLineExtraForm.varianceAmount,
        };

        this.subscriptions.push(
          this.poService.addOrderLine(values)
            .subscribe(
              () => {
                this.updatePOInMemory();
              },
              err => {
                this.notiService.notify(err);
                this.loading = false;
              })
        );
      }
    }
  }

  updatePOInMemory() {
    // update the PO in memory
    const thisPO = this.poService.allPurchaseOrdersForCompany.find(i => i.id === this.purchaseOrderId);
    thisPO.orderTotal += this.orderLineExtraForm.varianceAmount;

    if (this.orderLineExtraForm.fromPurchaseOrderId) {
      this.purchaseOrderId = this.orderLineExtraForm.fromPurchaseOrderId;
      this.orderLineExtraForm.fromPurchaseOrderId = null;
      this.orderLineExtraForm.varianceAmount = -this.orderLineExtraForm.varianceAmount;
      this.submit();
    } else {
      this.loading = false;
      this.popupClose.emit(new OrderLookup(0, this.orderLineExtraForm.varianceAmount));
    }
  }

  onPurchaseOrderSelectionChanged(dropDownBox, event) {
    if (event.selectedRowKeys.length > 0) {
      this.orderLineExtraForm.fromPurchaseOrderId = event.selectedRowsData[0]?.id;
      dropDownBox.close();
    } else {
      this.orderLineExtraForm.fromPurchaseOrderId = null;
    }
  }

  calculateCostCentreSortValue(data) {
    return this.costCentres.find(i => i.id === data?.costCentreId)?.orderNumber;
  }

  zeroOrderPopup() {
    this.popupVisible = false;

    // set the current job se we default
    this.jobService.currentJob = this.jobService.jobs.find(i => i.id === this.jobId);
    this.jobService.setCurrentJob(this.jobService.currentJob?.jobNumber);

    const modalRef = this.modalService.open(CreateZeroPoComponent, { windowClass: 'modal-edit2' });

    modalRef.result.then((purchaseOrderId) => {
      if (purchaseOrderId) {
        this.popupClose.emit(new OrderLookup(purchaseOrderId, 0));
      } else {
        this.popupVisible = true;
      }
    }, () => {
      this.popupVisible = true;
    });
  }
}
