<div class="page">

  <dx-data-grid *ngIf="!loadingData && !loading" #invoiceGrid id="invoiceGrid" columnResizingMode="widget" [height]="gridHeight"
    [dataSource]="dataSource" [allowColumnReordering]="true" [allowColumnResizing]="true" [repaintChangesOnly]="true"
    [showBorders]="true" [rowAlternationEnabled]="true" [wordWrapEnabled]="true" [hoverStateEnabled]="true" columnMinWidth="10"
    (onToolbarPreparing)="onToolbarPreparing($event, 'toolbarTemplate')" (onCellPrepared)="onCellPrepared($event)"
    (onEditorPreparing)="onEditorPreparing($event)" (onEditingStart)="onEditingStart($event)"
    [(selectedRowKeys)]="selectedRowKeys"
    (onExporting)="gridService.onExporting($event, 'on-hold')">

    <dxo-search-panel [visible]="true" location="after" [width]="200"></dxo-search-panel>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-scrolling mode="standard" [preloadEnabled]="true" rowRenderingMode="virtual"></dxo-scrolling>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping #expand [autoExpandAll]="true" [contextMenuEnabled]="false"></dxo-grouping>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-column-chooser [enabled]="true" mode="dragAndDrop"> </dxo-column-chooser>
    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="invoices-on-hold" [savingTimeout]="500">
    </dxo-state-storing>

    <dxo-editing [mode]="editMode" [allowUpdating]="true" [allowAdding]="false" [allowDeleting]="true" [useIcons]="true"
      [selectTextOnEditStart]="true">
    </dxo-editing>

    <dxo-selection [mode]="selectionMode"></dxo-selection>

    <dxi-column dataField="vendorId" caption="Vendor" dataType="number" [setCellValue]="setVendorCellValue"
      [minWidth]="125" [calculateSortValue]="calculateVendorSortValue">
      <dxo-lookup [dataSource]="vendors" displayExpr="vendorName" valueExpr="id"></dxo-lookup>
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="orderNumber" width="120" [setCellValue]="setOrderNumberCellValue"
      editCellTemplate="editOrderCellTemplate">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="invoiceNumber" width="100">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="invoiceDate" dataType="date" width="80" format="d/M/yy" alignment="center"
      [setCellValue]="setInvoiceDateValue">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="dueDate" dataType="date" width="80" format="d/M/yy" alignment="center">
    </dxi-column>

    <dxi-column dataField="totalIncGST" dataType="number" width="106" [format]="{ type: 'fixedPoint', precision: 2 }"
      [setCellValue]="setTotalIncGSTCellValue">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="totalGST" dataType="number" width="90" [format]="{ type: 'fixedPoint', precision: 2 }"
      [setCellValue]="setTotalGSTCellValue">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="totalExGST" dataType="number" [visible]='false'>
    </dxi-column>

    <dxi-column dataField="jobNumber" caption="Job" dataType="string" [allowEditing]="false" width="100"
      [calculateCellValue]='calculateJobNumber' [visible]='false'>
    </dxi-column>

    <dxi-column dataField="jobId" caption="Job Address" dataType="number" [allowEditing]="false" [minWidth]="125">
      <dxo-lookup [dataSource]="jobs" displayExpr="jobAddressString" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="costCentreId" caption="Cost Centre" dataType="number" [allowEditing]="false"
      [minWidth]="125" [calculateSortValue]='calculateCostCentreSortValue'>
      <dxo-lookup [dataSource]="costCentres" displayExpr="description" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="siteManager" [caption]="calculateSiteManagerTitle()" dataType="string" [allowEditing]="false"
      width="100" [calculateCellValue]='calculateSiteManager'>
    </dxi-column>

    <dxi-column dataField="estimator" [caption]="calculateEstimatorTitle()" dataType="string" [allowEditing]="false"
      width="100" [calculateCellValue]='calculateEstimator'>
    </dxi-column>

    <dxi-column dataField="purchaseOrderId" dataType="number" width="100" [visible]="false">
    </dxi-column>

    <dxi-column dataField="remainingBudget" caption="Remaining Budget ExGST Will Be" dataType="number" width="105"
      [calculateCellValue]='calculateRemainingBudgetAmount' [format]="{ type: 'fixedPoint', precision: 2 }"
      [allowEditing]="false" [allowFiltering]="true">
    </dxi-column>

    <dxi-column dataField="allocatedToUserId" caption="Allocated To" dataType="number" width="105"
      [editorOptions]="{ showClearButton: true }">
      <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="authorisedByUserId" caption="Approved by" dataType="number" width="100"
      [allowEditing]="false">
      <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="description" caption="Comment" [minWidth]="100" cellTemplate="descriptionTemplate">
    </dxi-column>

    <dxi-column dataField="onHoldType" caption="Hold" dataType="string" [allowEditing]="false" width="50"
      cellTemplate="holdTypeCellTemplate">
    </dxi-column>

    <dxi-column dataField="isWorkDone" caption="Authorise" dataType="boolean" width="55" [allowFiltering]="false">
    </dxi-column>

    <dxi-column dataField="currentActivity" width="140" [allowEditing]="false" [visible]="false"
      [calculateCellValue]="calculateActivityDesc">
    </dxi-column>

    <dxi-column dataField="createUserId" caption="Entered by" dataType="number" width="120" [allowEditing]="false"
      [visible]="false">
      <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="createDate" caption="Entered" dataType="date" width="95" format="d/M/yy" alignment="center"
      [allowEditing]="false" [visible]="false">
    </dxi-column>

    <dxi-column dataField="modifiedUserId" caption="Modified by" dataType="number" width="120" [allowEditing]="false"
      [visible]="false">
      <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="modifiedDate" caption="Modified" dataType="date" width="95" format="d/M/yy"
      alignment="center" [allowEditing]="false" [visible]="false">
    </dxi-column>

    <dxi-column dataField="vendorGroup" caption="Vendor Group" [visible]="false" dataType="string" width="120"
      [calculateCellValue]="calcVendorGroup" [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="glAccountType" caption="Vendor Type" [visible]="false" dataType="string" width="120"
      [calculateCellValue]="calcGlAccountType" [allowEditing]="false">
    </dxi-column>

    <dxi-column type="buttons" [width]="240" [fixed]="true">
      <dxi-button name="edit"></dxi-button>
      <dxi-button icon="toolbox" [onClick]="deleteInvoice"></dxi-button>
      <dxi-button *ngIf="editMode === 'row'" icon="tags" hint="Add simple extra" [onClick]="extrasPopup"
        hint='Enter Extra'></dxi-button>
      <dxi-button icon="share" [onClick]="splitInvoice" hint='Split Invoice'></dxi-button>
      <dxi-button icon="doc" [onClick]="downloadAttachment" hint='Download Invoice'></dxi-button>
      <dxi-button icon="download" [onClick]="openPO" hint='Download PO'></dxi-button>
      <dxi-button icon="unselectall" [onClick]="openHistory" hint='See related invoices'></dxi-button>
      <dxi-button icon="import" [onClick]="openCostCentre" hint='Go to Construction Orders'></dxi-button>
    </dxi-column>


    <!-- summary totals -->
    <dxo-summary>
      <dxi-total-item column="vendorId" summaryType="count" displayFormat="{0} rows">
      </dxi-total-item>
      <dxi-total-item column="totalExGST" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="totalGST" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}">
      </dxi-total-item>
      <dxi-total-item column="totalIncGST" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}">
      </dxi-total-item>
    </dxo-summary>


    <!-- Templates -->
    <div *dxTemplate="let cellInfo of 'editOrderCellTemplate'">
      <dx-drop-down-box *ngIf="editMode === 'batch'" [dropDownOptions]="dropDownOptionsPOs"
        [dataSource]="purchaseOrders" [value]="getPoNumber(cellInfo)" [displayExpr]="getPoNumberCellValue"
        valueExpr="id" contentTemplate="purchaseOrderContentTemplate" [showClearButton]="true"
        (onValueChanged)="onPODropDownChanged(cellInfo, $event)">
        <div *dxTemplate="let e of 'purchaseOrderContentTemplate'">
          <dx-data-grid [dataSource]="purchaseOrders" keyExpr="id" [remoteOperations]="false" height="99%"
            [showBorders]="true" [rowAlternationEnabled]="true" [wordWrapEnabled]="true"
            [selectedRowKeys]="[getPoNumber(cellInfo)]" [hoverStateEnabled]="true" [autoNavigateToFocusedRow]="true"
            [focusedRowKey]="getPoNumber(cellInfo)" [focusedRowEnabled]="true"
            (onSelectionChanged)="onPurchaseOrderSelectionChanged(cellInfo, e.component, $event)"
            (onCellPrepared)="onPOListCellPrepared($event)">

            <dxo-search-panel [visible]="true" location="before" width="250px"></dxo-search-panel>
            <dxo-scrolling mode="standard" [preloadEnabled]="true" rowRenderingMode="virtual"></dxo-scrolling>
            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-selection mode="single"></dxo-selection>

            <dxi-column dataField="id" [visible]='false'></dxi-column>

            <dxi-column dataField="jobId" caption="Job" dataType="number" [width]="100" sortOrder="asc">
              <dxo-lookup [dataSource]="jobs" displayExpr="jobNumber" valueExpr="id"></dxo-lookup>
            </dxi-column>

            <dxi-column dataField="poNumber" caption="PO#" [width]="90" sortOrder="asc"></dxi-column>

            <dxi-column dataField="costCentreId" dataType="number" caption="Cost Code" [width]="200"
              [calculateSortValue]="calculateCostCentreSortValue">
              <dxo-lookup [dataSource]="costCentres" displayExpr="description" valueExpr="id">
              </dxo-lookup>
            </dxi-column>

            <dxi-column dataField="vendorId" dataType="number" caption="Vendor"
              [calculateSortValue]="calculateVendorSortValue">
              <dxo-lookup [dataSource]="vendors" displayExpr="vendorName" valueExpr="id">
              </dxo-lookup>
            </dxi-column>

            <dxi-column dataField="remainingBudgetExGst" caption="Remaining Budget" dataType="number" [width]="90"
              format="#,##0" [calculateCellValue]="calcRemainingBudget"></dxi-column>

            <dxi-column dataField="hasInvoicesNotApproved" dataType="boolean" [visible]="false"></dxi-column>

            <dxi-column type="buttons" [width]="50">
              <dxi-button icon="download" [onClick]="openPOFromDropdown" hint='Download PO'>
              </dxi-button>
            </dxi-column>
          </dx-data-grid>
        </div>
      </dx-drop-down-box>

      <dx-text-box *ngIf="editMode === 'row'" id="orderEditCell" [(value)]="cellInfo.value"
        (onValueChanged)="setEditedValue($event, cellInfo)">
      </dx-text-box>
      <dx-button *ngIf="editMode === 'row'" id="orderEditButton" icon="edit" (onClick)="orderLookup(cellInfo)">
      </dx-button>
      <js-order-lookup *ngIf="showOrderLookupPopup" [originalOrderId]="selectedOrderId" [rowData]="cellInfo.data"
        [orderLookupSwitch]="orderLookupSwitch" (purchaseOrderId)="getOrderFromLookup($event, cellInfo)">
      </js-order-lookup>
    </div>

    <div *dxTemplate="let data of 'toolbarTemplate'">
      <div>
        <dx-select-box id="selectVendorGroup" [dataSource]="vendorGroupsFilter" displayExpr="description" valueExpr="id"
          placeholder="All Groups" [width]="286" [(value)]='selectedVendorGroupId'
          (onValueChanged)='setUpDataSource(true)'>
        </dx-select-box>
      </div>
    </div>

    <div id="holdClass" *dxTemplate="let cellInfo of 'holdTypeCellTemplate'">
      <div matTooltip='B=Budget Hold, W=Work Not Done, V=Vendor Hold'>
        {{cellInfo.value}}
      </div>
    </div>

    <div *dxTemplate="let cell of 'descriptionTemplate'">
      <div style="white-space: pre-wrap;">{{cell.text}}</div>
    </div>
  </dx-data-grid>

  <span>Hold Status: B=Over Budget, W=Work Not Complete or Manual Authorise required (Can override by Authorising),
    V=Vendor Hold (inactive, invoices not allowed or vendor cannot invoice to this order)</span>
</div>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#employee' }" [(visible)]="loadingFile"
  [showIndicator]="true" [showPane]="true" [shading]="true" [hideOnOutsideClick]="false">
</dx-load-panel>


<js-extra-select *ngIf="addExtraVisible" [purchaseOrderId]="purchaseOrderId" [poNumber]="poNumber" [jobId]="jobId"
  (popupClose)="extrasClose($event)"></js-extra-select>

<js-order-lookup *ngIf="showOrderLookupPopupSplits" [originalOrderId]="selectedOrderId" [rowData]="invoiceData"
  [fromOnHold]="true" [orderLookupSwitch]="orderLookupSwitch" (splitPurchaseOrders)="splitOrdersFromLookup($event)">
</js-order-lookup>


<!-- Delete -->
<dx-popup class="popup" [width]="510" [height]="140" [showTitle]="true" title="Tools" [shading]="false"
  [dragEnabled]="true" [hideOnOutsideClick]="true" [(visible)]="deletePopupVisible">
  <div *dxTemplate="let data of 'content'">
    <dx-button id="load-button" text="Reject" type="default" (onClick)="rejectInvoice()" [width]="100"> </dx-button>
    <dx-button id="load-button" text="Back-Charge" type="default" (onClick)="backChargeInvoice()" [width]="120">
    </dx-button>
    <dx-button id="load-button" text="Logs" type="normal" (onClick)="showInvoiceLogs()" [width]="100"> </dx-button>
    <dx-button id="load-button" text="Delete" type="danger" (onClick)="deleteInvoiceGo()" [width]="100"> </dx-button>
  </div>
</dx-popup>


<!-- process -->
<dx-popup class="popup" [width]="130" [height]="130" [showTitle]="true" [shading]="false" [dragEnabled]="true"
  [hideOnOutsideClick]="true" [(visible)]="processPopupVisible">
  <div *dxTemplate="let data of 'content'">
    <dx-button text="Process" type="success" (onClick)="processInvoicesGo()">
    </dx-button>
  </div>
</dx-popup>
