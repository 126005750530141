<div class="page">

  <h4>
    Order Fix - moves budget from one PO to another where spare budget exists
  </h4>

  <js-loading-spinner *ngIf="loading"></js-loading-spinner>



  <div *ngIf="!loading">
    <js-job-number [maxJobStringWidth]="350" (refreshJobNumber)='onJobNumberChanged($event)'></js-job-number>
    <br>
    <br>

    <span>Extra Code:</span>
    <dx-select-box [(ngModel)]="varianceCodeId" [dataSource]="varianceCodes" valueExpr="id" [searchEnabled]="true"
      displayExpr="description" placeholder="Select code" [width]="500">
    </dx-select-box>
  </div>


  <dx-button *ngIf="!loading" text="Run" type="default" (onClick)="runFix(false)">
  </dx-button>

  <dx-button *ngIf="!loading" text="Run for All Jobs" type="danger" (onClick)="runFix(true)" class="ms-2">
  </dx-button>

</div>