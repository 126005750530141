import { GridService } from './../../services/grid.service';
import { AuthService } from './../../services/auth.service';
import { UserService } from './../../services/felixApi/user.service';
import { Component, Input, OnChanges, OnInit, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MaintenanceService } from './../../services/felixApi/maintenance.service';
import { GlobalService } from './../../services/global.service';
import { PoService } from '../../services/felixApi/po.service';
import { UtilsService } from './../../services/utils.service';
import { EstimatingService } from './../../services/felixApi/estimating.service';
import { NotificationService } from './../../services/notification.service';
import { Phase } from '../../dtos/phase';
import { UnitOfMeasure } from '../../dtos/unitOfMeasure';
import { Vendor } from '../../dtos/vendor';
import CustomStore from 'devextreme/data/custom_store';
import { OrderHeader } from './../../dtos/order-header';
import { DxDataGridComponent } from 'devextreme-angular';
import { OrderLine } from '../../dtos/order-line';
import { Subscription } from 'rxjs';
import { JobService } from '../../services/felixApi/job.service';
import { SelectedItem } from '../../dtos/selected-item';
import { PurchaseOrder } from '../../dtos/purchase-order';
import { VarianceCode } from '../../dtos/variance-code';
import { VarianceReason } from '../../dtos/variance-reason';
import { User } from '../../dtos/user';
import { PriceFileItem } from '../../dtos/price-file-item';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CopyPoItemsComponent } from '../copy-po-items/copy-po-items.component';
import { OrderLineLengthForAdd } from '../../dtos/order-line-length';
import { MoveItemsToNewPoComponent } from '../move-items-to-new-po/move-items-to-new-po.component';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'js-order-lines',
  templateUrl: './order-lines.component.html',
  styleUrls: ['./order-lines.component.scss']
})
export class OrderLinesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() orderHeader: OrderHeader;
  @Input() selectedCostCentreId: number;
  @Input() gridHeight: number;
  @Input() gridWidth: number;
  @Input() isRefresh: boolean; // to force component change detection

  @Output() refreshTotals: EventEmitter<number> = new EventEmitter<number>();
  @Output() refreshOrders: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild('orderLineGrid', { static: false }) orderLineGrid: DxDataGridComponent;
  @ViewChild('lookupDataGrid', { static: false }) lookupDataGrid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  orderLineId: number;
  hasSizes: boolean;
  orderLineQuantity: any;
  orderLineDescription: any;
  phases: Phase[] = [{ id: 0, orderNo: 0, phaseCode: 'Default', description: 'Default' }];
  lengthPopupVisible = false;
  unitsOfMeasure: UnitOfMeasure[];
  vendors: Vendor[];
  dropDownOptions: object;
  dropDownVendor: object;
  dataSource: CustomStore;
  lengthsDataSource: CustomStore;
  recipeData: CustomStore;
  autoNavigateToFocusedRow = true;
  isDropDownBoxOpened = false;
  updatedData: any;
  loading = false;
  loadingLengths = false;
  multiAddHeight: number;
  selectedItems: SelectedItem[];
  selectedItem: SelectedItem;
  currentPurchaseOrder: PurchaseOrder;
  currentPhase: Phase;
  deletePopupVisible: boolean;
  recordToDeleteId: number;
  purchaseOrderIdToEdit: number;
  editMode = 'batch';
  purchaseOrderIdToDelete: any;
  deleteOrderPopupVisible: boolean;
  cancelOrderPopupVisible: boolean;
  deletingInProgress: boolean;
  editingItem: boolean;
  varianceCodes: VarianceCode[];
  varianceReasons: VarianceReason[];
  varianceCodeId: number;
  varianceReason: string;
  multiAddVarianceCodeId: number;
  multiAddVarianceReason: string;
  adHocReason: VarianceReason;
  currentOrderVendorId: any;
  currentOrderPhaseId: any;
  previousOrderLine: OrderLine;
  extrasPopupVisible: boolean;
  currentRowDesc: any;
  users: User[];
  isAddVariancePopupVisible: boolean;
  ordersGridHeight: number;
  originalGridHeight: number;
  resetPopupVisible: boolean;
  markItemsPopupVisible: boolean;
  deleteAllPopupVisible = false;
  varianceCodePlaceHolder: string;
  varianceReasonPlaceHolder: string;
  orderHasCallUp: boolean;
  setCallUpToFalse: boolean;
  changeVendorOrderPopupVisible: boolean;
  costDate: Date;
  vendorId: number;
  revaluePopupVisible: boolean;
  markOrderNotSent: boolean;
  lookupDataGridData: CustomStore;
  costCentres: PriceFileItem[];
  multiAddCostCentreId: number;
  changingCostCentres: boolean;
  currentRowExtras: CustomStore;
  isExtrasAdmin: boolean;
  addingExtraToChange: boolean;
  isCommentVisible: boolean;
  isPriceListRight = false;
  resizeableHandles: string;
  ordersGridWidth: any;
  multiAddWidth: number;
  originalGridWidth: number;
  productCodeWidth: number;
  phaseWidth: number;
  qtyWidth: number;
  orderLineGridVisible = false;
  clearEditPopupVisible = false;
  descriptionWidth = 900;
  codeLookupData: CustomStore;
  showFullPriceFile = false;
  codeLookupVisible = true;
  showExtraCodeOnAddSelected = false;
  activeVendors: Vendor[];
  selectedPriceFileItemId: number; // to remember where in the price file the user has been
  isZeroRates = false;
  currentPriceFileCode: string; // for adding from price list
  addLengthPopupVisible: boolean;
  orderLineLengthsForAdd: OrderLineLengthForAdd[] = [];
  changePhasePopupVisible: boolean;
  newPhaseId: number;
  canChangeDescriptionOrRateOnOrderLines: boolean;
  checkDescriptionChangePopup: boolean;
  rowData: any;
  descriptionChangeValue: string;
  changeDescriptionValue: boolean;
  changeRateValue: number;
  checkRateChangePopup: boolean;
  resetPopupMessage: string;

  constructor(
    private poService: PoService,
    private globalService: GlobalService,
    private estimatingService: EstimatingService,
    private notiService: NotificationService,
    private utilService: UtilsService,
    private jobService: JobService,
    private maintenanceService: MaintenanceService,
    private userService: UserService,
    private authService: AuthService,
    public gridService: GridService,
    private modalService: NgbModal,
  ) {
    this.onEditingStart = this.onEditingStart.bind(this);
    this.onEditorPreparing = this.onEditorPreparing.bind(this);
    this.onInitNewRow = this.onInitNewRow.bind(this);
    this.setRecipeCellValue = this.setRecipeCellValue.bind(this);
    this.setQtyCellValue = this.setQtyCellValue.bind(this);
    this.setSupplierQtyCellValue = this.setSupplierQtyCellValue.bind(this);
    this.setVendorCellValue = this.setVendorCellValue.bind(this);
    this.deletePopup = this.deletePopup.bind(this);
    this.editPopup = this.editPopup.bind(this);
    this.setEditingPO = this.setEditingPO.bind(this);
    this.deleteByVO = this.deleteByVO.bind(this);
    this.showEditButton = this.showEditButton.bind(this);
    this.showCancelButton = this.showCancelButton.bind(this);
    this.clearEditButton = this.clearEditButton.bind(this);
    this.clearEditingPO = this.clearEditingPO.bind(this);
    this.clearEditingPOGo = this.clearEditingPOGo.bind(this);
    this.isDeleteIconVisible = this.isDeleteIconVisible.bind(this);
    this.isEditIconVisible = this.isEditIconVisible.bind(this);
    this.isExtrasIconVisible = this.isExtrasIconVisible.bind(this);
    this.disableEntryField = this.disableEntryField.bind(this);
    this.extrasPopup = this.extrasPopup.bind(this);
    this.editLengths = this.editLengths.bind(this);
    this.hidingLengthPopup = this.hidingLengthPopup.bind(this);
    this.cancelAddPopup = this.cancelAddPopup.bind(this);
    this.onResizeEnd = this.onResizeEnd.bind(this);
    this.setRateCellValue = this.setRateCellValue.bind(this);
    this.markItemsToBeCheckedGo = this.markItemsToBeCheckedGo.bind(this);
    this.lockLine = this.lockLine.bind(this);
    this.calculateItemSortOrder = this.calculateItemSortOrder.bind(this);
    this.changeVendorForPO = this.changeVendorForPO.bind(this);
    this.addRecipesFromListGo = this.addRecipesFromListGo.bind(this);
    this.revaluePO = this.revaluePO.bind(this);
    this.onCellPrepared = this.onCellPrepared.bind(this);
    this.saveState = this.saveState.bind(this);
    this.loadState = this.loadState.bind(this);
    this.onResizableInitialized = this.onResizableInitialized.bind(this);
    this.getSentDate = this.getSentDate.bind(this);
    this.getVendorForOrder = this.getVendorForOrder.bind(this);
    this.editLengthsForAdd = this.editLengthsForAdd.bind(this);
    this.onEditingAddGridStart = this.onEditingAddGridStart.bind(this);
    this.hidingAddLengthPopup = this.hidingAddLengthPopup.bind(this);
    this.changePhaseForPO = this.changePhaseForPO.bind(this);
    this.changeDescriptionGo = this.changeDescriptionGo.bind(this);
    this.setRateValue = this.setRateValue.bind(this);

    this.dropDownOptions = { width: 1000, minHeight: 500 };
    this.dropDownVendor = { width: 400, minHeight: 100 };
  }

  ngOnInit() {
    this.isExtrasAdmin = this.authService.isAdminOrSuper();
    const poPerm = this.authService.areaPermissions.find(i => i.applicationArea === 'OrderExtras');
    if (this.authService.isAdminOrSuper() || poPerm?.permissionType === 'Admin') {
      this.isExtrasAdmin = true;
    }

    this.canChangeDescriptionOrRateOnOrderLines = this.maintenanceService.orderControl.canChangeDescriptionOrRateOnOrderLines;

    this.phases = this.phases.concat(this.maintenanceService.phases);
    this.unitsOfMeasure = this.maintenanceService.unitOfMeasures;
    this.varianceCodes = this.maintenanceService.varianceCodes;
    this.varianceReasons = this.maintenanceService.varianceReasons;
    this.users = this.userService.users;
    this.costCentres = this.estimatingService.costCentres;

    this.loadState(); // to set price list location
  }

  ngOnChanges() {
    this.purchaseOrderIdToEdit = null;
    this.currentOrderVendorId = null;
    this.selectedPriceFileItemId = null;
    this.editMode = 'batch'; // reset in case
    this.orderLineLengthsForAdd = [];

    this.vendors = [];
    // put the vendors for this cc at the top
    this.maintenanceService.allVendors.forEach(vendor => {
      if (this.estimatingService.priceFileItemVendorRates
        .find(i => i.priceFileGroupId === this.selectedCostCentreId && i.vendorId === vendor.id)) {
        this.vendors.push(vendor);
      }
    });

    // now the rest
    this.maintenanceService.allVendors.filter(i => !i.hideFromOrders).forEach(vendor => {
      if (!this.estimatingService.priceFileItemVendorRates
        .find(i => i.priceFileGroupId === this.selectedCostCentreId && i.vendorId === vendor.id)) {
        this.vendors.push(vendor);
      }
    });

    this.activeVendors = this.vendors.filter(i => i.isActive);

    this.multiAddCostCentreId = this.selectedCostCentreId; // we can change this but will be reset on change of cc
    this.changingCostCentres = true;
    setTimeout(() => {
      this.changingCostCentres = false;
    }, 100); // wait

    this.varianceCodePlaceHolder = this.orderHeader.isLocked ? 'Select code' : 'Select code - leave blank for non-extra';
    this.varianceReasonPlaceHolder = this.orderHeader.isLocked ? 'Select reason' : 'Select reason - leave blank for non-extra';

    if (this.originalGridHeight !== this.gridHeight || this.originalGridWidth !== this.gridWidth) {
      this.loading = true;
      this.orderLineGridVisible = false;

      this.loadState(); // to set price list location
      this.setWidths();

      this.originalGridHeight = this.gridHeight;
      this.originalGridWidth = this.gridWidth;

      setTimeout(() => {
        this.loading = false;
      }, 100); // wait
    }

    if (this.orderLineGrid?.instance?.hasEditData()) {
      this.notiService.showInfo('There are outstanding data changes not saved that may have been ignored');
    }

    this.setDescWidth();
    this.setUpRecipeData();
    this.setUpDataSet();

    // if version 2.14.5a then we ask to reset the layout -- TODO remove this after a few months *******
    if (this.selectedCostCentreId && environment.fullVersion === '2.14.5a' && localStorage.getItem('resetLayout-2.14.5a') !== 'true') {
      if (window.innerWidth < 2000) {
        this.notiService.showInfo('For screen sizes less than 2000px wide, you are best to set the zoom to 90% or less', '', { disableTimeOut: true, closeButton: true });
      }
      this.resetPopupVisible = true;
      this.resetPopupMessage = 'Update the grid for the new Extra Description field';
      // write local storage so we don't get this message again
      localStorage.setItem('resetLayout-2.14.5a', 'true');
    }
  }

  setWidths() {
    this.gridWidth = (window.innerWidth - 430);
    if (this.isPriceListRight) {
      this.ordersGridHeight = this.gridHeight;
      this.multiAddHeight = this.gridHeight;
      this.ordersGridWidth = this.gridWidth * 0.65;
      this.multiAddWidth = this.gridWidth - this.ordersGridWidth;
      this.resizeableHandles = 'right';
      this.productCodeWidth = 100;
      this.phaseWidth = 80;
      this.qtyWidth = 75;
    } else {
      this.ordersGridHeight = this.gridHeight * 0.6;
      this.multiAddHeight = this.gridHeight - this.ordersGridHeight - 5;
      this.multiAddWidth = this.gridWidth;
      this.ordersGridWidth = this.gridWidth;
      this.multiAddWidth = this.gridWidth;
      this.resizeableHandles = 'bottom';
      this.productCodeWidth = 150;
      this.phaseWidth = 100;
      this.qtyWidth = 100;
    }
    this.descriptionWidth = this.ordersGridWidth / 3.5;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setDescWidth() {
    if (this.multiAddWidth < 1200) {
      this.isCommentVisible = false;
    } else {
      this.isCommentVisible = true;
    }
  }

  setUpRecipeData() {
    this.recipeData = new CustomStore({
      key: 'recipeCode',
      loadMode: 'raw',
      load: () => this.estimatingService.recipesAndItems
        .filter(i => i.costCentreId || (!i.costCentreId && this.selectedCostCentreId === -1))
    });

    this.codeLookupData = new CustomStore({
      key: 'recipeCode',
      loadMode: 'raw',
      load: () => this.estimatingService.recipesAndItems
        .filter(i => i.costCentreId === this.selectedCostCentreId || (!i.costCentreId && this.selectedCostCentreId === -1))
    });

    this.setUplookupDataGridData(this.selectedCostCentreId);
  }

  setUplookupDataGridData(selectedCostCentreId) {
    this.lookupDataGridData = new CustomStore({
      key: 'recipeCode',
      loadMode: 'raw',
      load: () => this.estimatingService.recipesAndItems
        .filter(i => i.costCentreId === selectedCostCentreId)
    });
  }

  setRecipeCellValue(rowData, value, originalData) {
    if (value) {
      let isValid = true;
      rowData.priceFileCode = value;

      const recipe = this.estimatingService.recipesAndItems.find(i => i.recipeCode === value);

      if (recipe) {
        rowData.description = recipe.vendorItemDescription && recipe.vendorItemDescription !== ''
          ? recipe.vendorItemDescription : recipe.description;
        rowData.unitOfMeasure = recipe.unitOfMeasure;
        rowData.recipeItemId = recipe.id;
        rowData.priceFileItemId = recipe.priceFileItemId;
        rowData.vendorItemDescription = recipe.vendorItemDescription;
        rowData.itemGroupDescription = recipe.subGroupItemDesc;
        rowData.hasSizes = recipe.hasSizes;
        if (!this.purchaseOrderIdToEdit || this.currentOrderVendorId === recipe.vendorId) {
          rowData.vendorId = recipe.vendorId;
          if (recipe.rate) {
            rowData.rate = recipe.rate.toFixed(2);

            // check we have the correct vendor selected
            const priceFileItem = this.estimatingService.getPriceFileItemForVendor(recipe.priceFileItemId, rowData.vendorId, this.orderHeader.baseDate, this.orderHeader.districtId);
            if (priceFileItem) {
              rowData.productCode = priceFileItem.productCode;
              rowData.supplierQuantity =
                this.maintenanceService.calcRoundedQty(originalData?.measuredQuantity,
                  priceFileItem.qtySizeControlId, priceFileItem.hasSizes);
            }
            rowData.lineTotal = (rowData.supplierQuantity ? rowData.supplierQuantity : 0)
              * this.estimatingService.calculateRate(+rowData.rate, rowData.unitOfMeasure);
          } else {
            isValid = false;
          }
        } else {
          // check we have the correct vendor selected
          const priceFileItem = this.estimatingService.getPriceFileItemForVendor(recipe.priceFileItemId, this.currentOrderVendorId, this.orderHeader.baseDate, this.orderHeader.districtId);
          if (priceFileItem) {
            rowData.productCode = priceFileItem.productCode;
            rowData.rate = priceFileItem.rate;
            rowData.supplierQuantity
              = this.maintenanceService.calcRoundedQty(originalData?.measuredQuantity,
                priceFileItem.qtySizeControlId, priceFileItem.hasSizes);
            rowData.lineTotal = rowData.supplierQuantity * this.estimatingService.calculateRate(rowData.rate, rowData.unitOfMeasure);
          } else {
            isValid = false;
          }
        }

        if (!isValid) {
          this.notiService.showInfo('Item not supplied by order vendor. Rate set to zero');
          rowData.productCode = '';
          rowData.rate = 0.00;
          rowData.lineTotal = 0;
        }
      }
    } else {
      rowData.priceFileCode = value;
      rowData.recipeItemId = null;
      rowData.priceFileItemId = null;
    }
  }

  setEditedQtyValue(valueChangedEventArg, cellInfo) {
    cellInfo.setValue(valueChangedEventArg.value);
  }

  onVendorDropDownChanged(cellInfo, e) {
    if (!e.value) {
      cellInfo.setValue(null);
    }
  }

  onVendorSelectionChanged(cellInfo, dropDownBox, event) {
    if (event.selectedRowKeys.length > 0) {
      cellInfo.setValue(event.selectedRowsData[0].id);
      dropDownBox.close();
    }
  }

  onEditorPreparing(e: any) {
    if (e.parentType !== 'dataRow') {
      return;
    } else {
      if (e.row.data.isLocked && !e.row.data.purchaseOrderId) {
        e.editorOptions.disabled = true;
      } else {
        if (!e.row.isNewRow
          && ((this.purchaseOrderIdToEdit && !e.row.data.purchaseOrderId)
            || (this.purchaseOrderIdToEdit && e.row.data.purchaseOrderId !== this.purchaseOrderIdToEdit)
            || (this.purchaseOrderIdToEdit && e.dataField === 'vendorId')
            || (!this.purchaseOrderIdToEdit && e.row.data.purchaseOrderId))) {
          // ordered lines are disabled for edit
          e.editorOptions.disabled = true;
        } else {
          if (e.dataField === 'recipeCode'
            || e.dataField === 'quantityString' || e.dataField === 'supplierQuantity') {
            return;
          }

          if (e.dataField === 'isToBeChecked') {
            if (this.purchaseOrderIdToEdit) {
              e.editorOptions.disabled = true;
            }
            return;
          }

          if (e.parentType === 'dataRow' && e.dataField === 'measuredQuantity') {
            e.editorOptions.disabled = true;
          } else if (e.dataField === 'phaseId' || e.dataField === 'addendumCode') {
            if (e.row.data.recipeItemId || this.purchaseOrderIdToEdit) {
              e.editorOptions.disabled = true;
            }
          } else if (e.row.data.recipeCode && e.row.data.recipeCode !== '' && e.dataField !== 'rate') {
            e.editorOptions.disabled = true;
          }

          if (e.dataField === 'description' || e.dataField === 'comment') {
            e.editorName = 'dxTextArea';
            e.editorOptions.autoResizeEnabled = true;
            let prevHeight = null;
            e.editorOptions.onInput = (args) => {
              const td = args.element.closest('td');
              if (prevHeight !== td.offsetHeight) {
                const overlay = e.element.querySelector('.dx-datagrid-focus-overlay');
                if (overlay != null) {
                  overlay.style.height = (td.offsetHeight + 1) + 'px';
                }
                prevHeight = td.offsetHeight;
              }
            };

            if (e.dataField === 'description') {
              e.editorOptions.onValueChanged = (args) => {
                if (!e.row.isNewRow) {
                  const editRowIndex = this.orderLineGrid.instance.getRowIndexByKey(this.orderLineId);
                  const priceFileCode = this.orderLineGrid.instance.cellValue(editRowIndex, 'priceFileCode') ?? '';
                  if (this.maintenanceService.orderControl.canChangeDescriptionOrRateOnOrderLines || priceFileCode === '') {
                    this.orderLineGrid.instance.cellValue(editRowIndex, 'description', args.value);
                  } else {
                    this.changeDescriptionValue = args.value;
                    this.checkDescriptionChangePopup = true;
                  }
                } else {
                  if (this.maintenanceService.orderControl.canChangeDescriptionOrRateOnOrderLines || !e.row.data.priceFileCode) {
                    e.setValue(args.value);
                  } else {
                    // disabled so should not happen
                  }
                }
              };

              e.editorOptions.disabled = e.row.isNewRow && e.row.data && e.row.data.priceFileCode && !this.maintenanceService.orderControl.canChangeDescriptionOrRateOnOrderLines;
            }
          }
          if (e.dataField === 'unitOfMeasure') {
            e.editorOptions.disabled = e.row.data && e.row.data.priceFileCode;
          }
          if (e.dataField === 'rate') {
            e.editorOptions.disabled = e.row.isNewRow && e.row.data && e.row.data.priceFileCode && !this.maintenanceService.orderControl.canChangeDescriptionOrRateOnOrderLines;
          }
        }
      }
    }
  }

  onRowPrepared(e) {
    if (e.rowType === 'data') {
      // if new line
      if (!e.data.id) {
        e.rowElement.style.background = 'lightyellow';
      } else {
        e.rowElement.className = e.rowElement.className.replace('dx-row-alt', 'dark-grey');
      }
    }
  }

  onRowPreparedPriceList(e) {
    if (e.rowType === 'data') {
      e.rowElement.className = e.rowElement.className.replace('dx-row-alt', 'dark-grey');
    }
  }

  getOrderLinesDataSet(): OrderLine[] {
    const dataSet = this.poService.orderLines.filter(i => i.costCentreId === this.selectedCostCentreId);

    dataSet.forEach(element => {
      element.orderPhaseDescription = this.poService.getOrderPhaseDescription(element);
    });
    return dataSet;
  }

  setUpDataSet() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: () => this.getOrderLinesDataSet(),
      insert: async (values) => {
        values.orderHeaderId = this.orderHeader.id;
        values.costCentreId = this.selectedCostCentreId;
        values.hasBlob = false;
        values.isDeleted = false;

        if (this.purchaseOrderIdToEdit) {
          values.purchaseOrderId = this.purchaseOrderIdToEdit;
          this.editingItem = false;
        }

        // we may have added
        values.varianceCodeId = this.varianceCodeId;
        values.varianceReason = this.varianceReason;

        // remember for next line
        this.currentOrderVendorId = values.vendorId;
        this.currentOrderPhaseId = values.phaseId;

        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.poService.addOrderLine(values).subscribe(res => {
              this.insertOrderLines(res, null);
              return resolve(res);
            }, err => {
              return reject(this.globalService.returnError(err));
            })
          ));
      },
      update: async (key, values) => {
        if (this.varianceCodeId) {
          values.varianceCodeId = this.varianceCodeId;
          values.varianceReason = this.varianceReason;
        }
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.poService.updateOrderLine(encodeURIComponent(key), values).subscribe(res => {
              this.updateOrderLines(res, key);
              return resolve(res);
            }, err => {
              return reject(this.globalService.returnError(err));
            })
          ));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.poService.deleteOrderLine(encodeURIComponent(key)).subscribe(res => {
              this.deleteOrderLine(key);
              return resolve();
            }, err => {
              return reject(this.globalService.returnError(err));
            })
          ));
      }
    });
  }

  insertOrderLines(newItem: OrderLine, key: number) {
    // we may have updated the order number and returned existing tasks so we need to delete them
    this.deleteOrderLine(key);

    newItem.orderPhaseDescription = this.poService.getOrderPhaseDescription(newItem);

    const priceFileItem = this.estimatingService.recipesAndItems.find(i => i.priceFileItemId === newItem.priceFileItemId);

    if (priceFileItem) {
      newItem.priceFileCode = priceFileItem?.recipeCode;
    } else {
      const recipe = this.estimatingService.recipesAndItems.find(i => i.id === newItem.recipeItemId);

      if (recipe) {
        newItem.priceFileCode = recipe?.recipeCode;
      }
    }

    // now add the newItems - the grid will sort
    // if it is deleted we ignore it
    if (!newItem.isDeleted) {
      this.poService.orderLines.push(newItem);
    }

    this.getExtrasAndRefresh();
  }

  updateOrderLines(newItem: OrderLine, key: number) {
    // find the existing one
    const existingItem = this.poService.orderLines.find(i => i.id === key);

    Object.assign(existingItem, newItem);

    existingItem.orderPhaseDescription = this.poService.getOrderPhaseDescription(newItem);

    const priceFileItem = this.estimatingService.recipesAndItems.find(i => i.priceFileItemId === newItem.priceFileItemId);

    if (priceFileItem) {
      existingItem.priceFileCode = priceFileItem?.recipeCode;
    } else {
      const recipe = this.estimatingService.recipesAndItems.find(i => i.id === newItem.recipeItemId);

      if (recipe) {
        existingItem.priceFileCode = recipe?.recipeCode;
      }
    }

    this.getExtrasAndRefresh();
  }

  getExtrasAndRefresh() {
    // if we have added/changed by extra we need to add the record
    if (this.varianceCodeId) {
      this.subscriptions.push(
        this.poService.getOrderLineExtras(this.orderHeader.jobId)
          .subscribe(
            () => {
              this.refreshTotals.emit(this.selectedCostCentreId);
              this.orderLineGrid.instance.refresh();
            },
            err => {
              this.notiService.notify(err);
            })
      );
    } else {
      this.refreshTotals.emit(this.selectedCostCentreId);
    }
  }

  deleteOrderLine(id: number) {
    this.previousOrderLine = null;

    if (id) {
      const foundId = this.poService.orderLines.findIndex(i => i.id === id);
      if (foundId >= 0) {
        this.previousOrderLine = this.poService.orderLines.find(i => i.id === id); // for extra calc
        this.poService.orderLines.splice(foundId, 1);
      }
    }
    this.refreshTotals.emit(this.selectedCostCentreId);
  }

  onCellPrepared(e) {
    if (e.rowType === 'data' && e.column.dataField === 'rate' && e.data.rate === null && e.data.recipeCode && e.data.recipeCode !== '') {
      e.cellElement.style.color = 'red';
    }
    if (e.rowType === 'data' && e.column.dataField === 'quantityString' && e.data.quantityString) {
      try {
        const calcCheck = this.utilService.calcQtyFromString(this.utilService.sanitizeQty(e.data.quantityString));
        if (isNaN(+calcCheck)) {
          e.cellElement.classList.add('redWhite');
        }
      } catch (err) {
        e.cellElement.classList.add('redWhite');
      }
    }

    if (e.rowType === 'data' && !e.data.supplierQuantity && (e.data.purchaseOrderId || this.checkLineHasExtras(e.data.id))) {
      // not the buttons
      if (!e.column.buttons) {
        e.cellElement.style.textDecoration = 'line-through';
      }
    } else {
      if (e.rowType === 'data' && e.column.dataField === 'measuredQuantity' && !e.data.measuredQuantity) {
        e.cellElement.style.color = 'red';
      }
      if (e.rowType === 'data' && e.column.dataField === 'supplierQuantity' && !e.data.supplierQuantity) {
        e.cellElement.style.color = 'red';
      }
    }
  }

  checkLineHasExtras(orderLineId: number): boolean {
    const existingExtra = this.poService.orderLineExtras
      .find(i => i.originalOrderLineId === orderLineId || i.orderLineId === orderLineId);
    if (existingExtra) {
      return true;
    }
    return false;
  }

  onResizeEnd(e) {
    this.loading = true;
    this.orderLineGridVisible = false;

    const currentState = JSON.parse(localStorage.getItem('ordersGrid'));

    if (this.isPriceListRight) {
      this.ordersGridWidth = e.width;
      this.multiAddWidth = this.gridWidth - this.ordersGridWidth;
    } else {
      this.ordersGridHeight = e.height;
      this.multiAddHeight = this.gridHeight - this.ordersGridHeight - 5;
    }
    this.setDescWidth();

    this.saveState(currentState);

    setTimeout(() => {
      this.loading = false;
    }, 100); // wait
  }

  onEditingStart(e) {
    this.orderLineId = e.data.id;
    this.orderLineQuantity = e.data.measuredQuantity ? e.data.measuredQuantity : 0;
    this.orderLineDescription = e.data.description;
    this.hasSizes = e.data.hasSizes;
  }

  onInitNewRow(e) {
    e.data.isToBeChecked = false;
    e.data.vendorId = this.currentOrderVendorId;
    e.data.phaseId = this.currentOrderPhaseId;
    this.hasSizes = false;

    if (this.purchaseOrderIdToEdit) {
      // open extras popup
      this.deletePopupVisible = true;
      this.recordToDeleteId = -1;
      this.editingItem = true;
    } else {
      // check if this is for an extra
      this.isAddVariancePopupVisible = true;
      this.showExtraCodeOnAddSelected = this.orderHeader.isLocked;
    }
  }

  onSelectionChanged(cellInfo, e, event) {
    if (event.selectedRowKeys.length > 0) {
      cellInfo.setValue(event.selectedRowsData[0].recipeCode);
      e.component.close();
    }
  }

  getGroupTitle(cellInfo) {
    return cellInfo.data.key.split(';')[1];
  }

  showEditButton(data) {
    if (data.data.key.indexOf('Unordered') >= 0 || this.purchaseOrderIdToEdit || data.data.collapsedItems) {
      return false;
    } else {
      return true;
    }
  }

  showCancelButton(data) {
    if (data.data.key.indexOf('Unordered') >= 0 || this.purchaseOrderIdToEdit) {
      return false;
    } else {
      const nonZeroLines = data.data.items?.find(i => i.supplierQuantity !== 0);
      if (nonZeroLines) {
        return true;
      }
    }
    return false;
  }

  showDateSent(data) {
    if (data.data.key.indexOf('Unordered') >= 0 || this.purchaseOrderIdToEdit) {
      return false;
    } else {
      if (data.key[0] && data.key[0].split(' ')) {
        const poNumber = data.key[0].split(' ')[1]; // key is in the format ['0;Order: 160.1']
        if (poNumber) {
          return this.poService.purchaseOrdersForJob?.find(i => i.poNumber === poNumber)?.printedDate;
        }
      }
    }
    return false;
  }

  getSentDate(data): Date {
    if (data.key[0] && data.key[0].split(' ')) {
      const poNumber = data.key[0].split(' ')[1]; // key is in the format ['0;Order: 160.1']
      if (poNumber) {
        return this.poService.purchaseOrdersForJob?.find(i => i.poNumber === poNumber)?.printedDate;
      }
    }
    return null;
  }

  getInvoiced(data): number {
    if (data.key[0] && data.key[0].split(' ')) {
      const poNumber = data.key[0].split(' ')[1]; // key is in the format ['0;Order: 160.1']
      if (poNumber) {
        const po = this.poService.purchaseOrdersForJob?.find(i => i.poNumber === poNumber);
        if (po) {
          let invoiceTotal = 0;
          const invoicesExist = this.poService.invoicesForThisJob.filter(i => i.poNumber === poNumber);
          if (invoicesExist) {
            invoicesExist.forEach(invoice => {
              invoiceTotal += invoice.totalExGST;
            });
          }
          return invoiceTotal;
        }
      }
    }
    return 0;
  }

  getOrderTotal(data): number {
    let orderTotal = 0;
    if (data.key[0] && data.key[0].split(' ')) {
      const poNumber = data.key[0].split(' ')[1]; // key is in the format ['0;Order: 160.1']
      if (poNumber) {
        const po = this.poService.purchaseOrdersForJob?.find(i => i.poNumber === poNumber);
        if (po) {
          const orderLinesForPO = this.poService.orderLines.filter(i => i.purchaseOrderId === po?.id);
          orderLinesForPO.forEach(orderLine => {
            orderTotal += orderLine.lineTotal ? orderLine.lineTotal : 0;
          });

          po.orderTotal = orderTotal;
        }
      }
    }
    return orderTotal;
  }

  getVendorForOrder(data): String {
    if (data.key[0] && data.key[0].split(' ')) {
      const poNumber = data.key[0].split(' ')[1]; // key is in the format ['0;Order: 160.1']
      if (poNumber) {
        const vendorId = this.poService.purchaseOrdersForJob?.find(i => i.poNumber === poNumber)?.vendorId;
        return this.vendors.find(i => i.id === vendorId)?.vendorName;
      }
    }
    return '';
  }

  clearEditButton(data) {
    if (this.purchaseOrderIdToEdit && data.data?.items && data.data.items[0]?.purchaseOrderId === this.purchaseOrderIdToEdit) {
      return true;
    } else {
      return false;
    }
  }

  disableEntryField(cellInfo) {
    if (cellInfo.row.isNewRow) {
      if (cellInfo.column.dataField === 'rate' && cellInfo.data.priceFileCode && !this.maintenanceService.orderControl.canChangeDescriptionOrRateOnOrderLines) {
        return true;
      }
      return false;
    } else if ((cellInfo.data.isLocked && !cellInfo.data.purchaseOrderId)
      || (!cellInfo.data.purchaseOrderId && this.purchaseOrderIdToEdit)
      || (this.purchaseOrderIdToEdit && cellInfo.data.purchaseOrderId !== this.purchaseOrderIdToEdit)
      || (!this.purchaseOrderIdToEdit && cellInfo.data.purchaseOrderId)
      || (this.purchaseOrderIdToEdit && cellInfo.column.dataField === 'vendorId')) {
      return true;
    } else {
      return false;
    }
  }

  onMultiRowClick(e) {
    let keys = e.component.getSelectedRowKeys();
    const index = keys.indexOf(e.key);

    if (index > -1) {
      keys.splice(index, 1);
    } else {
      keys = keys.concat(e.key);
    }

    e.component.selectRows(keys);
  }

  editLengths(cellInfo) {
    if (!this.orderLineId || this.orderLineGrid?.instance.hasEditData()) {
      // we haven't added the line yet
      this.notiService.showWarning('Save data before entering lengths');
    } else {
      this.lengthPopupVisible = true;
      this.loadingLengths = false;
      this.orderLineQuantity = cellInfo.data.measuredQuantity ? cellInfo.data.measuredQuantity : 0;

      this.lengthsDataSource = new CustomStore({
        key: 'id',
        load: async () => {
          return new Promise((resolve, reject) =>
            this.subscriptions.push(
              this.poService.getOrderLineLengths(this.orderLineId).subscribe(res => {
                return resolve(res);
              }, err => {
                return reject(this.globalService.returnError(err));
              })
            ));
        },
        insert: async (values) => {
          return new Promise((resolve, reject) =>
            this.subscriptions.push(
              this.poService.addOrderLineLength(
                { originalOrderLineId: this.orderLineId, quantity: values.quantity, length: values.length }
              ).subscribe(res => {
                this.orderLineQuantity = res.orderLineQuantity;
                return resolve(res);
              }, err => {
                return reject(this.globalService.returnError(err));
              })
            ));
        },
        update: async (key, values) => {
          return new Promise((resolve, reject) =>
            this.subscriptions.push(
              this.poService.updateOrderLineLength(encodeURIComponent(key), values).subscribe(res => {
                this.orderLineQuantity = res.orderLineQuantity;
                return resolve(res);
              }, err => {
                return reject(this.globalService.returnError(err));
              })
            ));
        },
        remove: async (key) => {
          return new Promise((resolve, reject) =>
            this.subscriptions.push(
              this.poService.deleteOrderLineLength(encodeURIComponent(key)).subscribe(res => {
                this.orderLineQuantity = res.orderLineQuantity;
                return resolve();
              }, err => {
                return reject(this.globalService.returnError(err));
              })
            ));
        }
      });
    }
  }

  changeDescriptionGo(changeDesc: boolean) {
    this.checkDescriptionChangePopup = false;
    if (changeDesc) {
      if (this.orderLineId) {
        const editRowIndex = this.orderLineGrid.instance.getRowIndexByKey(this.orderLineId);
        this.orderLineGrid.instance.cellValue(editRowIndex, 'description', this.changeDescriptionValue);
        this.orderLineGrid.instance.cellValue(editRowIndex, 'priceFileItemId', null);
        this.orderLineGrid.instance.cellValue(editRowIndex, 'priceFileCode', '');
        this.orderLineGrid.instance.cellValue(editRowIndex, 'recipeItemId', null);
        this.orderLineGrid.instance.cellValue(editRowIndex, 'productCode', '');
      } else {
        // should not get this far
      }
    }
  }

  setRateValue(valueChangedEventArg, cellInfo) {
    const editRowIndex = this.orderLineGrid.instance.getRowIndexByKey(this.orderLineId);
    const priceFileCode = this.orderLineGrid.instance.cellValue(editRowIndex, 'priceFileCode') ?? '';
    if (this.maintenanceService.orderControl.canChangeDescriptionOrRateOnOrderLines || priceFileCode === '') {
      cellInfo.setValue(valueChangedEventArg.value);
    } else {
      this.changeRateValue = valueChangedEventArg.value;
      this.checkRateChangePopup = true;
    }
  }

  changeRateGo(changeDesc: boolean) {
    this.checkRateChangePopup = false;
    if (changeDesc) {
      const editRowIndex = this.orderLineGrid.instance.getRowIndexByKey(this.orderLineId);
      this.orderLineGrid.instance.cellValue(editRowIndex, 'rate', this.changeRateValue);
      this.orderLineGrid.instance.cellValue(editRowIndex, 'priceFileItemId', null);
      this.orderLineGrid.instance.cellValue(editRowIndex, 'priceFileCode', '');
      this.orderLineGrid.instance.cellValue(editRowIndex, 'recipeItemId', null);
      this.orderLineGrid.instance.cellValue(editRowIndex, 'productCode', '');
    }
  }

  setQtyCellValue(rowData, value, originalData) {
    if (value) {
      rowData.quantityString = value;
      rowData.measuredQuantity = this.utilService.calcQtyFromString(this.utilService.sanitizeQty(value));

      const priceFileItem = this.estimatingService.getPriceFileItemForVendor(originalData.priceFileItemId, originalData.vendorId, this.orderHeader.baseDate, this.orderHeader.districtId);

      const supplierQty = this.maintenanceService
        .calcRoundedQty(rowData.measuredQuantity, priceFileItem?.qtySizeControlId, priceFileItem?.hasSizes);
      rowData.supplierQuantity = supplierQty;

      rowData.lineTotal = supplierQty * this.estimatingService.calculateRate(originalData.rate, originalData.unitOfMeasure);
    } else {
      rowData.quantityString = null;
      rowData.measuredQuantity = 0;
      rowData.supplierQuantity = 0;
      rowData.lineTotal = 0;
    }
  }

  setSupplierQtyCellValue(rowData, value, originalData) {
    if (value) {
      rowData.supplierQuantity = value;
    } else {
      rowData.supplierQuantity = 0;
    }

    rowData.lineTotal = rowData.supplierQuantity * this.estimatingService.calculateRate(originalData.rate, originalData.unitOfMeasure);
  }

  setRateCellValue(rowData, value, originalData) {
    if (value) {
      rowData.rate = value;
    } else {
      rowData.rate = 0;
    }

    rowData.lineTotal = originalData.supplierQuantity * this.estimatingService.calculateRate(rowData.rate, originalData.unitOfMeasure);
  }

  setVendorCellValue(rowData, value, originalData) {
    if (rowData.priceFileItemId ?? originalData.priceFileItemId) {
      const priceFileItem = this.estimatingService.getPriceFileItemForVendor(originalData.priceFileItemId, value, this.orderHeader.baseDate, this.orderHeader.districtId);
      if (priceFileItem) {
        rowData.productCode = priceFileItem.productCode;

        // we need to check if the units mean we don't change the rate
        const rate = rowData.rate ?? originalData.rate;
        const unitOfMeasure = rowData.unitOfMeasure ?? originalData.unitOfMeasure;
        rowData.rate = (this.unitsOfMeasure.find(i => i.id === priceFileItem.unitOfMeasureId)?.isDoNotRecost ?? false) ? rate : priceFileItem.rate;

        rowData.lineTotal = originalData.supplierQuantity * this.estimatingService.calculateRate(rowData.rate, unitOfMeasure);

        // do we change the description
        const currentDescription = rowData.description ?? originalData.description;
        const originalVendorItem = this.estimatingService.getPriceFileItemForVendor(originalData.priceFileItemId, originalData?.vendorId, this.orderHeader.baseDate, this.orderHeader.districtId);

        if (priceFileItem.vendorItemDescription
          && priceFileItem.vendorItemDescription !== ''
          && currentDescription !== priceFileItem.vendorItemDescription) {
          // we can change only if the description has not been manually updated - e.g. to add a quote number or colour
          if (currentDescription === originalVendorItem?.description || currentDescription === originalVendorItem?.vendorItemDescription) {
            rowData.description = priceFileItem.vendorItemDescription;
          } else {
            this.notiService.showWarning('Description has been manually changed - cannot update to the new vendor\'s description');
          }
        } else if (currentDescription !== priceFileItem.description) {
          // we can change only if the description has not been manually updated - e.g. to add a quote number or colour
          if (currentDescription === originalVendorItem?.description || currentDescription === originalVendorItem?.vendorItemDescription) {
            rowData.description = priceFileItem.description;
          } else {
            this.notiService.showWarning('Description has been manually changed - cannot update to the new vendor\'s description');
          }
        }
      } else {
        this.notiService.showInfo('Item not supplied by vendor (possibly for this pricing date)');
        rowData.productCode = '';
      }
    }

    rowData.vendorId = value;
  }

  closeLengthPopup() {
    this.lengthPopupVisible = false;
  }

  hidingLengthPopup() {
    // update the orderLine with the new qty
    let measuredQty = 0;
    const editRowIndex = this.orderLineGrid.instance.getRowIndexByKey(this.orderLineId);

    if (editRowIndex >= 0) {
      measuredQty = this.orderLineGrid.instance.cellValue(editRowIndex, 'measuredQuantity');
    }

    if (measuredQty !== this.orderLineQuantity) {
      this.loadingLengths = true;
      this.orderLineGrid.instance.cellValue(editRowIndex, 'measuredQuantity', this.orderLineQuantity);
      this.orderLineGrid.instance.cellValue(editRowIndex, 'quantityString', this.orderLineQuantity.toString());
      this.orderLineGrid.instance.saveEditData();
      this.orderLineGrid.instance.refresh();
    }
  }

  hidingVariancePopup(e) {
    if (!this.varianceCodeId || !this.varianceReason) {
      this.orderLineGrid.instance.cancelEditData();
    }
    this.addingExtraToChange = false;
  }

  hidingNewItemPopup(e) {
    if ((this.varianceCodeId && !this.varianceReason) || (!this.varianceCodeId && this.varianceReason)) {
      this.notiService.showInfo('Both variance code & reson need to be specified or left blank');
      this.isAddVariancePopupVisible = true;
      this.showExtraCodeOnAddSelected = true;
      e.cancel = true;
    }
  }

  cancelAddPopup() {
    this.isAddVariancePopupVisible = false;
    this.orderLineGrid.instance.cancelEditData();
  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          icon: 'expand',
          onClick: this.expandOrderLines.bind(this),
          matTooltip: 'Expand All Rows'
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          width: 40,
          icon: 'collapse',
          onClick: this.collapsOrderLines.bind(this),
          matTooltip: 'Collapse All Rows'
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          text: this.isPriceListRight ? 'Set Price List Bottom' : 'Set Price List Right',
          onClick: this.setPriceListRight.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          text: 'Reset Layout',
          onClick: this.clearStatePersistance.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          icon: 'trash',
          onClick: this.deleteAll.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          icon: 'check',
          onClick: this.markItemsToBeChecked.bind(this)
        }
      });
  }

  onMultiLoadToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift(
      {
        location: 'before',
        locateInMenu: this.multiAddWidth < 1050 ? 'always' : 'auto',
        widget: 'dxSelectBox',
        options: {
          width: 250,
          placeholder: 'Extras code...',
          items: this.varianceCodes,
          displayExpr: 'description',
          valueExpr: 'id',
          showClearButton: true,
          onValueChanged: this.onMultiExtrasCodeValueChanged.bind(this),
          value: this.multiAddVarianceCodeId
        }
      },
      {
        location: 'before',
        locateInMenu: this.multiAddWidth < 1050 ? 'always' : 'auto',
        widget: 'dxSelectBox',
        options: {
          width: this.multiAddWidth < 1100 ? 250 : this.multiAddWidth < 1200 ? 300 : 400,
          placeholder: 'Extras reason...',
          items: this.varianceReasons,
          displayExpr: 'reason',
          valueExpr: 'reason',
          showClearButton: true,
          acceptCustomValue: true,
          onCustomItemCreating: this.addCustomItem.bind(this),
          onValueChanged: this.onMultiReasonValueChanged.bind(this),
          value: this.multiAddVarianceReason
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          icon: 'expand',
          onClick: this.expandAll.bind(this),
          matTooltip: 'Collapse All Rows'
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          icon: 'collapse',
          onClick: this.collapseAll.bind(this),
          matTooltip: 'Collapse All Rows'
        }
      },
      {
        location: 'after',
        // locateInMenu: 'auto',
        widget: 'dxSelectBox',
        options: {
          width: 100,
          items: this.costCentres,
          displayExpr: 'priceFileCode',
          valueExpr: 'id',
          onValueChanged: this.onMultiCostCentreChanged.bind(this),
          value: this.multiAddCostCentreId
        }
      });
  }

  collapseAll() {
    this.lookupDataGrid.instance.collapseAll();
  }

  expandAll() {
    this.lookupDataGrid.instance.expandAll();
  }

  onCodeGridPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.push(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          type: 'outline',
          text: this.showFullPriceFile ? 'Show Cost Centre' : 'Show Full Price File',
          onClick: this.showAllPriceFile.bind(this)
        }
      });
  }

  showAllPriceFile() {
    this.showFullPriceFile = !this.showFullPriceFile;

    if (this.showFullPriceFile) {
      this.codeLookupData = new CustomStore({
        key: 'recipeCode',
        loadMode: 'raw',
        load: () => this.estimatingService.recipesAndItems
          .filter(i => i.costCentreId || (!i.costCentreId && this.selectedCostCentreId === -1))
      });
    } else {
      this.codeLookupData = new CustomStore({
        key: 'recipeCode',
        loadMode: 'raw',
        load: () => this.estimatingService.recipesAndItems
          .filter(i => i.costCentreId === this.selectedCostCentreId || (!i.costCentreId && this.selectedCostCentreId === -1))
      });
    }

    this.codeLookupVisible = false;
    setTimeout(() => {
      this.codeLookupVisible = true;
    }, 200); // wait
  }

  clearStatePersistance() {
    this.resetPopupVisible = true;
  }

  clearStatePersistanceGo() {
    this.resetPopupVisible = false;
    this.resetPopupMessage = '';
    this.loading = true;
    this.gridWidth = (window.innerWidth - 430);
    localStorage.removeItem('ordersGrid');
    setTimeout(() => {
      this.setWidths();
      this.setDescWidth();
      this.loading = false;
    }, 300); // wait
  }

  markItemsToBeChecked() {
    // mark all unorderd lines as to be checked
    this.markItemsPopupVisible = true;
  }

  markItemsToBeCheckedGo() {
    this.markItemsPopupVisible = false;
    this.loading = true;

    this.subscriptions.push(
      this.poService.markItemsToBeChecked(this.orderHeader.jobId, this.selectedCostCentreId).subscribe(
        res => {
          this.getOrderLines(false);
        },
        err => {
          this.notiService.notify(err);
          this.loading = false;
        })
    );
  }

  deleteAll() {
    // mark all unorderd lines as to be checked
    this.deleteAllPopupVisible = true;
  }

  deleteAllGo() {
    this.deleteAllPopupVisible = false;
    this.loading = true;

    this.subscriptions.push(
      this.poService.deleteAllItems(this.orderHeader.jobId, this.selectedCostCentreId).subscribe(
        res => {
          this.getOrderLines(true);
        },
        err => {
          this.notiService.notify(err);
          this.loading = false;
        })
    );
  }

  addRecipesFromListGo(e) {
    e.cancel = true;
    if (this.purchaseOrderIdToEdit
      && (!this.multiAddVarianceCodeId || !this.multiAddVarianceReason || this.multiAddVarianceReason === '')) {
      this.notiService.showInfo('Editing and order. Extras code and reason are required');
    } else if (this.orderHeader.isLocked && (!this.multiAddVarianceCodeId || !this.multiAddVarianceReason || this.multiAddVarianceReason === '')) {
      this.notiService.showInfo('Job Orders are locked. Extras code and reason are required');
    } else if (this.multiAddVarianceCodeId && (!this.multiAddVarianceReason || this.multiAddVarianceReason === '')) {
      this.notiService.showInfo('For Extras: Must specify both Extras code and reason');
    } else if (!this.multiAddVarianceCodeId && this.multiAddVarianceReason && this.multiAddVarianceReason !== '') {
      this.notiService.showInfo('For Extras: Must specify both Extras code and reason');
    } else {
      this.selectedItems = [];

      e.changes.forEach(element => {
        if (element.data.quantity) {
          const priceFileItemWithRate = this.estimatingService.recipesAndItems.find(i => i.recipeCode === element.key);
          const measuredQuantity = this.utilService.calcQtyFromString(this.utilService.sanitizeQty(element.data.quantity));

          if (priceFileItemWithRate) {
            this.selectedItem = {
              costCentreId: this.selectedCostCentreId,
              recipeItemId: priceFileItemWithRate.id ? priceFileItemWithRate.id : 0,
              priceFileItemId: priceFileItemWithRate.priceFileItemId ? priceFileItemWithRate.priceFileItemId : 0,
              description: priceFileItemWithRate.vendorItemDescription && priceFileItemWithRate.vendorItemDescription !== ''
                ? priceFileItemWithRate.vendorItemDescription : priceFileItemWithRate.description,
              unitOfMeasure: priceFileItemWithRate.unitOfMeasure,
              hasSizes: priceFileItemWithRate.hasSizes,
              quantityString: element.data.quantity,
              measuredQuantity: measuredQuantity,
              supplierQuantity: this.maintenanceService
                .calcRoundedQty(measuredQuantity, priceFileItemWithRate.qtySizeControlId, priceFileItemWithRate.hasSizes),
              vendorId: priceFileItemWithRate.vendorId,
              rate: priceFileItemWithRate.rate,
              productCode: priceFileItemWithRate.productCode,
              phaseId: element.data.phaseId ? element.data.phaseId : null,
              orderLineId: null,
              orderLineLengths: this.orderLineLengthsForAdd.filter(i => i.priceFileCode === element.key)
            };
            this.selectedItems.push(this.selectedItem);
          }
        }
      });

      this.lookupDataGrid.instance.cancelEditData();
      this.lookupDataGrid.instance.clearFilter('search');
      this.orderLineLengthsForAdd = [];

      // add the recipes selected
      if (this.selectedItems && this.selectedItems.length) {
        this.loading = true;
        this.isDropDownBoxOpened = false;

        this.subscriptions.push(
          this.poService.addOrderLinesFromSelectedRecipes(this.orderHeader.id, this.selectedItems,
            this.multiAddVarianceCodeId, this.multiAddVarianceReason, this.purchaseOrderIdToEdit).subscribe(
              () => {
                this.purchaseOrderIdToEdit = null;
                if (this.multiAddVarianceCodeId) {
                  this.multiAddVarianceCodeId = null; // reset
                  this.multiAddVarianceReason = '';
                  this.getOrderLineExtras(false);
                } else {
                  this.getOrderLines(false);
                }
              },
              err => {
                this.notiService.notify(err);
                this.loading = false;
              })
        );
      }
    }
  }

  getOrderLines(refreshOrders: boolean) {
    this.subscriptions.push(
      this.poService.getOrderLines(this.jobService.currentJob.id, null)
        .subscribe(
          () => {
            this.dataSource = null;
            this.loading = false;
            this.setUpDataSet();
            if (this.lookupDataGrid && this.lookupDataGrid.instance) {
              this.lookupDataGrid.instance.clearFilter('search');
            }

            if (refreshOrders) {
              this.refreshOrders.emit(this.selectedCostCentreId);
            } else {
              this.refreshTotals.emit(this.selectedCostCentreId);
            }
          },
          err => {
            this.notiService.notify(err);
            this.loading = false;
          })
    );
  }

  getOrderLineExtras(refreshOrders: boolean) {
    this.subscriptions.push(
      this.poService.getOrderLineExtras(this.jobService.currentJob.id)
        .subscribe(
          () => {
            this.getOrderLines(refreshOrders);
          },
          err => {
            this.notiService.notify(err);
            this.getOrderLines(refreshOrders);
          })
    );
  }

  isEditIconVisible = (e) => {
    if (this.purchaseOrderIdToEdit && e.row.data.purchaseOrderId === this.purchaseOrderIdToEdit) {
      return true;
    }
    return false;
  }

  isExtrasIconVisible = (e) => {
    if (!this.purchaseOrderIdToEdit) {
      return this.checkLineHasExtras(e.row.data.id);
    }
    return false;
  }

  isDeleteIconVisible = (e) => {
    if (!e.row.data.isLocked && !e.row.data.purchaseOrderId && !this.purchaseOrderIdToEdit) {
      return !this.checkLineHasExtras(e.row.data.id);
    }
    return false;
  }

  isDeleteWithVOVisible = (e) => {
    if (!e.row.data.isLocked && !e.row.data.purchaseOrderId && !this.purchaseOrderIdToEdit) {
      const existingExtras = this.poService.orderLineExtras
        .filter(i => i.originalOrderLineId === e.row.data.id || i.orderLineId === e.row.data.id);
      if (!existingExtras) {
        return false;
      }
      let totalExtras = 0;
      existingExtras.forEach(existingExtra => {
        totalExtras += existingExtra.varianceTotal;
      });
      if (totalExtras) {
        return true;
      }
    }
    return false;
  }

  isLockIconVisible = (e) => {
    if (e.row.data.purchaseOrderId || this.purchaseOrderIdToEdit) {
      return false;
    }
    return true;
  }

  editPopup(e) {
    // we may want to just flag as cancelled
    this.recordToDeleteId = e.row.key;
    this.editingItem = true;

    if (this.varianceCodeId && this.varianceReason && !this.addingExtraToChange) {
      this.beginEditByVO();
    } else {
      this.deletePopupVisible = true;
    }
  }

  deletePopup(e) {
    // we may want to just flag as cancelled
    this.deletePopupVisible = true;
    this.recordToDeleteId = e.row.key;
    this.editingItem = false;
  }

  deleteByVO() {
    this.deletePopupVisible = false;
    const editRowIndex = this.orderLineGrid.instance.getRowIndexByKey(this.recordToDeleteId);
    if (editRowIndex >= 0) {
      this.orderLineGrid.instance.cellValue(editRowIndex, 'Measured Qty', '');
      this.orderLineGrid.instance.cellValue(editRowIndex, 'Actual Qty', 0);
      this.orderLineGrid.instance.cellValue(editRowIndex, 'Vendor Qty', 0);
      this.orderLineGrid.instance.saveEditData();
      this.orderLineGrid.instance.refresh();
    }
  }

  beginEditByVO() {
    this.deletePopupVisible = false;
    const editRowIndex = this.orderLineGrid.instance.getRowIndexByKey(this.recordToDeleteId);
    if (editRowIndex >= 0) {
      this.orderLineGrid.instance.editRow(editRowIndex);
    }
  }

  onSaving(e) {
    if (!this.varianceCodeId) {
      // if this item has extras it needs a code & reason unless the amount is not changing
      let anyHasExtras = false;
      e.changes.forEach(change => {
        if (change.data && change.data['lineTotal'] !== undefined && this.checkLineHasExtras(change.key)) {
          anyHasExtras = true;
        }
      });

      if (anyHasExtras) {
        if (e.changes.length > 1) {
          this.notiService.showInfo('If an item has extras it must be the only line changed');
        } else {
          this.addingExtraToChange = true;
          this.deletePopupVisible = true;
        }
        e.cancel = true;
      }
    }
  }

  saveEditByVO() {
    this.deletePopupVisible = false;
    this.addingExtraToChange = false;
    this.orderLineGrid.instance.saveEditData();
  }

  setEditingPO(data) {
    this.purchaseOrderIdToEdit = data.data.items[0].purchaseOrderId;
    this.currentOrderVendorId = data.data.items[0].vendorId;
    this.currentOrderPhaseId = data.data.items[0].phaseId;
    this.editMode = 'row';
    this.orderLineGrid.instance.refresh();
  }

  clearEditingPO() {
    this.clearEditPopupVisible = true;
    this.markOrderNotSent = false;
  }

  clearEditingPOGo() {
    if (this.markOrderNotSent) {
      this.subscriptions.push(
        this.poService.updatePurchaseOrder(this.purchaseOrderIdToEdit.toString(), { printedDate: null })
          .subscribe(
            () => {
              const po = this.poService.purchaseOrdersForJob.find(i => i.id === this.purchaseOrderIdToEdit);
              if (po) {
                po.printedDate = null;
              }
              this.clearEditingPOGo2();
            },
            err => {
              this.notiService.notify(err);
            })
      );
    } else {
      this.clearEditingPOGo2();
    }
  }

  clearEditingPOGo2() {
    this.clearEditPopupVisible = false;
    this.purchaseOrderIdToEdit = null;
    this.editMode = 'batch';
    this.orderLineGrid.instance.refresh();
    this.varianceCodeId = null;
    this.varianceReason = '';
  }

  deletePO(data) {
    if (this.orderLineGrid?.instance.hasEditData()) {
      this.notiService.showWarning('Please save or cancel changed data.');
    } else {
      this.purchaseOrderIdToDelete = data.data.items[0].purchaseOrderId;
      this.deleteOrderPopupVisible = true;
    }
  }

  deleteOrderGo() {
    this.deletingInProgress = true;
    this.subscriptions.push(
      this.poService.deletePurchaseOrder(this.purchaseOrderIdToDelete)
        .subscribe(
          () => {
            this.deletingInProgress = false;
            this.deleteOrderPopupVisible = false;
            this.loading = true;
            this.getOrderLines(true);
          },
          err => {
            this.notiService.notify(err);
            this.deletingInProgress = false;
          })
    );
  }

  cancelPO(data) {
    if (this.orderLineGrid?.instance.hasEditData()) {
      this.notiService.showWarning('Please save or cancel changed data.');
    } else {
      this.purchaseOrderIdToDelete = data.data.items[0].purchaseOrderId;
      this.cancelOrderPopupVisible = true;
      this.orderHasCallUp = false;
      this.setCallUpToFalse = false;
      this.deletingInProgress = true;

      this.checkOrderForTask();
    }
  }

  cancelOrderGo() {
    if (!this.varianceCodeId || !this.varianceReason || this.varianceReason === '') {
      this.notiService.showInfo('Extras code and reason are required');
    } else {
      this.deletingInProgress = true;
      this.subscriptions.push(
        this.poService.cancelPurchaseOrder(this.purchaseOrderIdToDelete, this.varianceCodeId, this.varianceReason, this.setCallUpToFalse)
          .subscribe(
            () => {
              this.deletingInProgress = false;
              this.cancelOrderPopupVisible = false;
              this.loading = true;
              this.getOrderLineExtras(true);
            },
            err => {
              this.notiService.notify(err);
              this.deletingInProgress = false;
            })
      );
    }
  }

  changeVendorForPO(data) {
    if (this.orderLineGrid?.instance.hasEditData()) {
      this.notiService.showWarning('Please save or cancel changed data.');
    } else {
      this.purchaseOrderIdToDelete = data.data.items[0].purchaseOrderId;
      this.changeVendorOrderPopupVisible = true;
      this.orderHasCallUp = false;
      this.setCallUpToFalse = false;
      this.deletingInProgress = true;
      this.isZeroRates = false;

      this.checkOrderForTask();
    }
  }

  changePhaseForPO(data) {
    if (this.orderLineGrid?.instance.hasEditData()) {
      this.notiService.showWarning('Please save or cancel changed data.');
    } else {
      this.purchaseOrderIdToDelete = data.data.items[0].purchaseOrderId;
      this.changePhasePopupVisible = true;
      this.newPhaseId = data.data.items[0].phaseId;
    }
  }

  revaluePO(data) {
    if (this.orderLineGrid?.instance.hasEditData()) {
      this.notiService.showWarning('Please save or cancel changed data.');
    } else {
      this.purchaseOrderIdToDelete = data.data.items[0].purchaseOrderId;
      this.revaluePopupVisible = true;
      this.markOrderNotSent = true;
    }
  }

  copyPOItems(data) {
    if (this.orderLineGrid?.instance.hasEditData()) {
      this.notiService.showWarning('Please save or cancel changed data.');
    } else {
      const modalRef = this.modalService.open(CopyPoItemsComponent, { windowClass: 'modal-1200' });
      modalRef.componentInstance.orderHeader = this.orderHeader;
      modalRef.componentInstance.isLocked = this.orderHeader.isLocked;
      modalRef.componentInstance.purchaseOrderId = data.data.items[0].purchaseOrderId;

      modalRef.result.then(() => {
        if (this.orderHeader.isLocked) {
          this.getOrderLineExtras(false);
        } else {
          this.getOrderLines(false);
        }
      });
    }
  }

  checkOrderForTask() {
    // check we have call up this order
    this.subscriptions.push(
      this.poService.getTasksForPurchaseOrder(this.purchaseOrderIdToDelete, this.orderHeader.jobId)
        .subscribe(
          jobTasks => {
            if (jobTasks?.length) {
              this.orderHasCallUp = true;
              this.setCallUpToFalse = true;
              const jobTaskCalledUp = jobTasks.find(i => i.calledDate);
              if (jobTaskCalledUp) {
                this.notiService.showWarning('The task for this order has been called up');
              }
            }
            this.deletingInProgress = false;
          },
          err => {
            this.deletingInProgress = false;
          })
    );
  }

  changeVendorForOrderGo() {
    if (!this.varianceCodeId || !this.varianceReason || this.varianceReason === '') {
      this.notiService.showInfo('Extras code and reason are required');
    } else {
      this.deletingInProgress = true;
      const effectiveDate = this.costDate.getFullYear()
        + ('0' + (this.costDate.getMonth() + 1)).toString().slice(-2)
        + ('0' + this.costDate.getDate()).slice(-2);

      this.subscriptions.push(
        this.poService.changeVendorForPurchaseOrder(this.purchaseOrderIdToDelete, this.varianceCodeId,
          this.varianceReason, this.setCallUpToFalse, effectiveDate, this.vendorId, this.isZeroRates)
          .subscribe(
            () => {
              this.deletingInProgress = false;
              this.changeVendorOrderPopupVisible = false;
              this.loading = true;
              this.getOrderLineExtras(true);
            },
            err => {
              this.notiService.notify(err);
              this.deletingInProgress = false;
            })
      );
    }
  }

  changePhaseForOrderGo() {
    this.deletingInProgress = true;
    this.subscriptions.push(
      this.poService.changePhaseForPurchaseOrder(this.purchaseOrderIdToDelete, this.newPhaseId)
        .subscribe(
          () => {
            this.deletingInProgress = false;
            this.changePhasePopupVisible = false;
            this.loading = true;
            this.getOrderLineExtras(true);
          },
          err => {
            this.notiService.notify(err);
            this.deletingInProgress = false;
          })
    );
  }

  revaluePOGo() {
    if (!this.varianceCodeId || !this.varianceReason || this.varianceReason === '') {
      this.notiService.showInfo('Extras code and reason are required');
    } else {
      this.deletingInProgress = true;
      const effectiveDate = this.costDate.getFullYear()
        + ('0' + (this.costDate.getMonth() + 1)).toString().slice(-2)
        + ('0' + this.costDate.getDate()).slice(-2);

      this.subscriptions.push(
        this.poService.revaluePurchaseOrder(this.purchaseOrderIdToDelete, this.varianceCodeId,
          this.varianceReason, effectiveDate, this.markOrderNotSent)
          .subscribe(
            revaluedLineCount => {
              this.notiService.showSuccess('Revalued ' + revaluedLineCount + ' line(s)');
              this.deletingInProgress = false;
              this.revaluePopupVisible = false;
              this.loading = true;
              this.getOrderLineExtras(true);
            },
            err => {
              this.notiService.notify(err);
              this.deletingInProgress = false;
            })
      );
    }
  }

  onLookupValueChanged(ea) {
    if (!this.adHocReason) {
      this.varianceReason = ea.value;
    } else {
      this.varianceReason = this.adHocReason.reason;
      this.adHocReason = null;
    }
  }

  onMultiReasonValueChanged(ea) {
    if (!this.adHocReason) {
      this.multiAddVarianceReason = ea.value;
    } else {
      this.multiAddVarianceReason = this.adHocReason.reason;
      this.adHocReason = null;
    }
  }

  onMultiExtrasCodeValueChanged(ea) {
    this.multiAddVarianceCodeId = ea.value;
  }

  addCustomItem(data) {
    if (!data.text) {
      data.customItem = null;
      return;
    }

    this.addToList(data.text);

    this.varianceReason = data.text;
    data.customItem = this.adHocReason;
  }

  addToList(adhocText: string) {
    const productIds = this.varianceReasons.map(function (item) {
      return item.id;
    });
    const incrementedId = Math.max.apply(null, productIds) + 1;

    this.adHocReason = new VarianceReason(incrementedId, adhocText);
    this.varianceReasons.push(this.adHocReason);
  }

  extrasPopup(e) {
    this.currentRowDesc = e.row.data.description;

    this.currentRowExtras = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: () => this.poService.orderLineExtras
        .filter(i => i.originalOrderLineId === e.row.data.id || i.orderLineId === e.row.data.id),
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.poService.updateOrderLineExtra(encodeURIComponent(key), values).subscribe(res => {
              // update store
              const orderLineExtra = this.poService.orderLineExtras.find(i => i.id === key);
              if (orderLineExtra) {
                if (values.varianceTotal !== undefined) {
                  orderLineExtra.varianceTotal = values.varianceTotal;
                }
                if (values.varianceCodeId !== undefined) {
                  orderLineExtra.varianceCodeId = values.varianceCodeId;
                }
                if (values.varianceReasonDescription !== undefined) {
                  orderLineExtra.varianceReasonDescription = values.varianceReasonDescription;
                }
              }
              return resolve(res);
            }, err => {
              return reject(this.globalService.returnError(err));
            })
          ));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.poService.deleteOrderLineExtra(encodeURIComponent(key)).subscribe(() => {
              const orderLineExtraIndex = this.poService.orderLineExtras.findIndex(i => i.id === key);
              if (orderLineExtraIndex >= 0) {
                this.poService.orderLineExtras.splice(orderLineExtraIndex, 1);
              }
              return resolve();
            }, err => {
              return reject(this.globalService.returnError(err));
            })
          ));
      }
    });

    this.extrasPopupVisible = true;
  }

  lockLine(e) {
    if (!e.data.isLocked && e.data.isToBeChecked) {
      this.notiService.showWarning('Cannot lock. Item needs to be checked');
    } else {
      let isLocked = true;
      if (e.data.isLocked) {
        isLocked = false;
      }

      this.orderLineGrid.instance.cellValue(e.rowIndex, 'isLocked', isLocked);
      this.orderLineGrid.instance.saveEditData();
      this.orderLineGrid.instance.refresh();
    }
  }

  calculateItemSortOrder(data) {
    const priceFileItem = this.estimatingService.allPriceFileItems.find(i => i.priceFileCode === data.priceFileCode);
    const subGroupItem = this.estimatingService.priceFileItemGroups.find(i => i.id === priceFileItem?.priceFileItemParentId);

    return (subGroupItem?.orderNumber + 1000000).toString() + data.priceFileCode;
  }

  onMultiCostCentreChanged(ea) {
    this.multiAddCostCentreId = ea.value;
    this.setUplookupDataGridData(ea.value);
  }

  expandOrderLines() {
    this.orderLineGrid.instance.expandAll();
  }

  collapsOrderLines() {
    this.orderLineGrid.instance.collapseAll();
  }

  setPriceListRight() {
    this.isPriceListRight = !this.isPriceListRight;

    this.loading = true;
    localStorage.removeItem('ordersGrid');

    this.setWidths();
    this.setDescWidth();

    setTimeout(() => {
      this.loading = false;
    }, 200); // wait
  }

  saveState(currentState) {
    if (currentState) {
      currentState.isPriceListRight = this.isPriceListRight ? true : false;

      currentState.ordersGridHeight = this.ordersGridHeight;
      currentState.multiAddHeight = this.multiAddHeight;
      currentState.ordersGridWidth = this.ordersGridWidth;
      currentState.multiAddWidth = this.multiAddWidth;

      const stateString = JSON.stringify(currentState);
      localStorage.setItem('ordersGrid', stateString);

      this.orderLineGridVisible = false;
      setTimeout(() => {
        this.loading = false;
        this.orderLineGridVisible = true;
      }, 200); // wait
    }
  }

  loadState(): any {
    const currentState = JSON.parse(localStorage.getItem('ordersGrid'));

    if (currentState) {
      this.isPriceListRight = currentState.isPriceListRight ? true : false;

      if (currentState.ordersGridHeight) {
        this.ordersGridHeight = currentState.ordersGridHeight;
      }
      if (currentState.multiAddHeight) {
        this.multiAddHeight = currentState.multiAddHeight;
      }
      if (currentState.ordersGridWidth) {
        this.ordersGridWidth = currentState.ordersGridWidth;
      }
      if (currentState.multiAddWidth) {
        this.multiAddWidth = currentState.multiAddWidth;
      }

      if (this.isPriceListRight) {
        this.ordersGridHeight = this.gridHeight;
        this.multiAddHeight = this.gridHeight;
        this.multiAddWidth = this.gridWidth - this.ordersGridWidth;
      } else {
        this.ordersGridWidth = this.gridWidth;
        this.multiAddHeight = this.gridHeight - this.ordersGridHeight - 5;
        this.multiAddWidth = this.gridWidth;
      }
    }
    return currentState;
  }

  onResizableInitialized(e) {
    // the orders grid will have trouble with the scroll if we don't wait till the resizable div init
    setTimeout(() => {
      this.orderLineGridVisible = true;
    }, 200); // wait
  }

  onKeyDownVendor(e) {
    e.component.open();
  }

  editLengthsForAdd(cellInfo) {
    this.currentPriceFileCode = cellInfo.key;
    this.addLengthPopupVisible = true;

    this.lengthsDataSource = new CustomStore({
      key: 'id',
      load: () => this.orderLineLengthsForAdd.filter(i => i.priceFileCode === this.currentPriceFileCode),
      insert: async (values) => {
        values.id = this.orderLineLengthsForAdd.filter(i => i.priceFileCode === this.currentPriceFileCode).length + 1;
        values.priceFileCode = this.currentPriceFileCode;
        this.orderLineLengthsForAdd.push(values);
        return values;
      },
      update: async (key, values) => {
        const changedRec = this.orderLineLengthsForAdd.find(i => i.priceFileCode === this.currentPriceFileCode && i.id === key);
        if (values.length !== undefined) {
          changedRec.length = values.length;
        }
        if (values.quantity !== undefined) {
          changedRec.quantity = values.quantity;
        }
        return changedRec;
      },
      remove: async (key) => {
        const recToDelete = this.orderLineLengthsForAdd.find(i => i.priceFileCode === this.currentPriceFileCode && i.id === key);
        if (recToDelete) {
          const index = this.orderLineLengthsForAdd.indexOf(recToDelete);
          this.orderLineLengthsForAdd.splice(index, 1);
        }
      }
    });
  }

  onEditingAddGridStart(e) {
    const priceFileItem = this.estimatingService.allPriceFileItems.find(i => i.priceFileCode === e.key);
    this.hasSizes = priceFileItem?.hasSizes;
  }

  setAddQtyCellValue(rowData, value, originalData) {
    if (value) {
      rowData.quantity = value;
    } else {
      rowData.quantity = 0;
    }
  }

  hidingAddLengthPopup() {
    const editRowIndex = this.lookupDataGrid.instance.getRowIndexByKey(this.currentPriceFileCode);

    let orderLineQuantity = 0;
    this.orderLineLengthsForAdd.filter(i => i.priceFileCode === this.currentPriceFileCode).forEach(element => {
      orderLineQuantity += element.length * element.quantity;
    });

    this.lookupDataGrid.instance.cellValue(editRowIndex, 'quantity', orderLineQuantity);
  }

  closeAddLengthPopup() {
    this.addLengthPopupVisible = false;
  }

  movePOItems(data) {
    if (this.orderLineGrid?.instance.hasEditData()) {
      this.notiService.showWarning('Please save or cancel changed data.');
    } else {
      const modalRef = this.modalService.open(MoveItemsToNewPoComponent, { windowClass: 'modal-1200' });
      modalRef.componentInstance.orderHeader = this.orderHeader;
      modalRef.componentInstance.purchaseOrderId = data.data.items[0].purchaseOrderId;

      modalRef.result.then(() => {
        this.getOrderLines(true);
      });
    }
  }
}
