<div class="modal-header">
  <h4 class="modal-title">Create Zero Value Order</h4>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="card p-2">
    <js-loading-spinner *ngIf="loading" [usePopUp]="false"></js-loading-spinner>

    <div *ngIf="!loading">
      <js-job-number [maxJobStringWidth]="350" (refreshJobNumber)='onJobNumberChanged($event)'></js-job-number>
      <br>
      <br>

      <div class="settings">
        <div class="column">
          <div class="field">
            <div class="label">Cost Centre:</div>
            <div class="value">
              <dx-drop-down-box [dropDownOptions]="dropDownOptions" [dataSource]="costCentres" [value]="costCentreId"
                displayExpr="description" valueExpr="id" contentTemplate="contentTemplate" [showClearButton]="true">
                <div *dxTemplate="let e of 'contentTemplate'">
                  <dx-data-grid [dataSource]="costCentres" keyExpr="id" [remoteOperations]="false" height="358px"
                    [showBorders]="true" [wordWrapEnabled]="true" [selectedRowKeys]="[costCentreId]"
                    [hoverStateEnabled]="true" [selection]="{ mode: 'single' }"
                    [scrolling]="{ mode: 'standard', preloadEnabled: true }" [autoNavigateToFocusedRow]="true"
                    (onSelectionChanged)="onCCDropDownChanged(e.component, $event)">

                    <dxo-search-panel [visible]="true" location="before" [width]="150"></dxo-search-panel>
                    <dxo-paging [enabled]="false"></dxo-paging>
                    <dxi-column dataField="priceFileCode" caption="Code" [width]="100"></dxi-column>
                    <dxi-column dataField="description"></dxi-column>
                  </dx-data-grid>
                </div>
              </dx-drop-down-box>
            </div>
          </div>

          <div class="field">
            <div class="label">Vendor:</div>
            <div class="value">
              <dx-select-box [(ngModel)]="vendorId" [searchEnabled]="true" [dataSource]="vendors" valueExpr="id"
                displayExpr="vendorName">
              </dx-select-box>
            </div>
          </div>

          <div class="field">
            <div class="label">Order line description:</div>
            <div class="value">
              <dx-text-area id="description" [(ngModel)]="description"> </dx-text-area>
            </div>
          </div>

          <div *ngIf="isAddAmount && !userCanCreatePoForInvoice">
            <div class="field">
              <div class="label">Extra Code:</div>
              <div class="value">
                <dx-select-box [(ngModel)]="varianceCodeId" [dataSource]="varianceCodes" valueExpr="id"
                  [searchEnabled]="true" displayExpr="description" placeholder="Select code">
                </dx-select-box>
              </div>
            </div>

            <div class="field">
              <div class="label">Extra Reason:</div>
              <div class="value">
                <dx-select-box [(ngModel)]="varianceReason" [dataSource]="varianceReasons" valueExpr="reason"
                  [acceptCustomValue]="true" [searchEnabled]="true" (onValueChanged)="onLookupValueChanged($event)"
                  (onCustomItemCreating)="addCustomItem($event)" displayExpr="reason" placeholder="Select reason">
                </dx-select-box>
              </div>
            </div>

            <div class="field">
              <div class="label">Amount:</div>
              <div class="value">
                <dx-number-box id="amount" [(ngModel)]="amount"> </dx-number-box>
              </div>
            </div>
          </div>

          <div *ngIf="userCanCreatePoForInvoice">
            <div class="field">
              <div class="label">Amount:</div>
              <div class="value">
                <dx-number-box id="amount" [ngModel]="totalExGST" [disabled]="!isAddAmount"> </dx-number-box>
              </div>
            </div>
          </div>

          <div *ngIf="isHandedOver" class="field">
            <div class="label">Order Type:</div>
            <div class="value">
              <dx-select-box [(ngModel)]="constructionStageId" [dataSource]="constructionStages" valueExpr="id"
                displayExpr="description">
              </dx-select-box>
            </div>
          </div>
        </div>
      </div>


      <dx-button text="Create" type="default" (onClick)="create()" class="mt-3"
        [disabled]="!(vendorId && costCentreId && jobId) || (isAddAmount && (!varianceCodeId || varianceReason === '')) || (isHandedOver && !constructionStageId)">
      </dx-button>

      <dx-button *ngIf="isExtrasWrite && !isAddAmount" text="Add Amount" type="outlined" (onClick)="isAddAmount = true"
        class="mt-3 ms-3">
      </dx-button>

      <dx-button *ngIf="isExtrasWrite && isAddAmount" text="Do NOT Add Amount" type="outlined"
        (onClick)="isAddAmount = false; varianceCodeId = null; varianceReason = ''; amount = null" class="mt-3 ms-3">
      </dx-button>
    </div>
  </div>
</div>