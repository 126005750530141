<div class="modal-header">
  <h4 class="modal-title">{{titleText}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="card p-2">

    <dx-data-grid [height]="550" [dataSource]="costCentres" keyExpr="id" [allowColumnReordering]="false"
      [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true" [rowAlternationEnabled]="true"
      [wordWrapEnabled]="true" [(selectedRowKeys)]="selectedCostCentreIds" [hoverStateEnabled]="true"
      (onRowClick)="onMultiRowClick($event)" (onSelectionChanged)="selectionChangedHandler()">

      <dxo-search-panel [visible]="true" location="before" [width]="200"></dxo-search-panel>
      <dxo-scrolling mode="standard"></dxo-scrolling>
      <dxo-paging [enabled]="false"></dxo-paging>

      <dxo-selection [allowSelectAll]="true" showCheckBoxesMode='always' mode="multiple">
      </dxo-selection>

      <dxi-column dataField="priceFileCode" caption="Code" dataType="string" [width]="100" alignment="center">
      </dxi-column>

      <dxi-column dataField="orderNumber" [visible]="false" sortOrder="asc">
      </dxi-column>

      <dxi-column dataField="description" caption="Cost Centre">
      </dxi-column>

      <dxi-column dataField="hasOrderLinesToBeChecked" caption="Has items to be checked" [width]="110">
      </dxi-column>

    </dx-data-grid>

    <br>
    <div class="dx-field">
      <div class="dx-field-label">Phase to generate:</div>
      <div class="dx-field-value">
        <dx-select-box [ngModel]="selectedPhaseId" [dataSource]="phases" valueExpr="id" displayExpr="description" (onValueChanged)="onPhaseChanged($event)">
        </dx-select-box>
      </div>
    </div>

    <div *ngIf="isHandedOver" class="dx-field">
      <div class="dx-field-label">Order Type:</div>
      <div class="dx-field-value">
        <dx-select-box [(ngModel)]="constructionStageId" [dataSource]="constructionStages" valueExpr="id"
          displayExpr="description">
        </dx-select-box>
      </div>
    </div>
    <br>

    <dx-button text="Go" class="me-2 mt-1" type="default" (onClick)="submit()"
      [disabled]="!constructionStageId || !selectedCostCentreIds.length">
    </dx-button>
  </div>

</div>
