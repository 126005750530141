import { AuthService } from './../services/auth.service';
import { JobCostCentre } from './../dtos/job-cost-centre';
import { EstimatingService } from './../services/felixApi/estimating.service';
import { MaintenanceService } from './../services/felixApi/maintenance.service';
import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { GlobalService } from '../services/global.service';
import { JobService } from '../services/felixApi/job.service';
import { PoService } from '../services/felixApi/po.service';
import { OrderHeader } from '../dtos/order-header';
import { NotificationService } from '../services/notification.service';
import { OrderHeaderModalComponent } from './order-header-modal/order-header-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { District } from '../dtos/district';
import { HouseType } from '../dtos/house-type';
import { PriceFileItem } from '../dtos/price-file-item';
import { Recipe } from '../dtos/recipe';
import CustomStore from 'devextreme/data/custom_store';
import { formatDate } from 'devextreme/localization';
import { Phase } from '../dtos/phase';
import { Vendor } from '../dtos/vendor';
import { OrderExportComponent } from './order-export/order-export.component';
import { DxDataGridComponent } from 'devextreme-angular';
import { AttachmentTypeEnum } from '../dtos/attachment-type.enum';
import { GenerateOrdersEnum } from '../dtos/generate-orders.enum';
import { CopyJobComponent } from './copy-job/copy-job.component';
import { ColourNoteComponent } from './colour-note/colour-note.component';
import { JobRolesComponent } from '../shared/job-roles/job-roles.component';
import * as saveAs from 'file-saver';
import { CostCentreSelectComponent } from './cost-centre-select/cost-centre-select.component';
import { OrderToolsMenuComponent } from './order-tools-menu/order-tools-menu.component';

@Component({
  selector: 'js-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit, OnDestroy {

  @ViewChild('costCentreGrid', { static: false }) costCentreGrid: DxDataGridComponent;

  jobNumber: string;
  loading = false;
  subscriptions: Subscription[] = [];
  jobId: number;
  orderHeader: OrderHeader;

  districts: District[];
  priceBookDescription: string;
  houseTypes: HouseType[];
  loadingData = true;
  costCentres: PriceFileItem[];
  gridHeight: number;
  selectedCostCentres: number[] = [];
  recipeData: CustomStore;
  gridWidth: number;
  toolBarEditButtonOptions: object;
  generateButtonOptions: object;
  loadButtonOptions: object;
  loadRecipeButtonOptions: object;
  quoteItemsButtonOptions: object;
  changeSupplierButtonOptions: object;
  recostButtonOptions: object;
  exportButtonOptions: object;
  copyButtonOptions: object;
  deleteButtonOptions: object;
  showRolesOptions: object;
  generateOrdersFor = [
    { id: 1, description: 'This cost centre only' },
    { id: 2, description: 'All cost centres' }
  ];
  generateOrdersForId: number;
  isUpdating = false;
  isRefresh = true;
  loadDescriptionTypes = [
    { id: 1, description: 'From price file' },
    { id: 2, description: 'From loaded file' }
  ];
  loadDescriptionTypeId = 1;
  fileValue: any[] = [];
  loadingExcelFile = false;
  stopIfExistingId = 1; // set default
  stopIfExistingTypes = [
    { id: 1, description: 'Stop load' },
    { id: 2, description: 'Add quantities together' }
  ];
  excelLoadPopupVisible = false;
  phases: Phase[] = [{ id: 0, orderNo: 0, phaseCode: '', description: 'Default' }];
  topToolbarWidth: number;
  recostPopupVisible: boolean;
  changeVendorPopupVisible: boolean;
  vendors: Vendor[];
  newVendorId: number;
  oldVendorId: number;
  grandTotal: number;
  recipeLoadPopupVisible: boolean;
  recipeId: number;
  recipes: Recipe[];
  dropDownOptions = { width: 1000, minHeight: 500 };
  selectedRowKeys: number[];
  generateOrdersEnum = GenerateOrdersEnum;
  isZeroRates = false; // should we zero any rates when changing vendor it item not supplied
  filteredCostCentres: PriceFileItem[];
  loadRecipeQuantity = 1;
  isDatePast: boolean;
  recostLockedLines = false;
  deleteAllPopupVisible = false;
  toolsMenuButtonOptions: object;
  canUseGlobalTools = false;
  jobNumberCheck: string;
  refreshButtonOptions: object;
  useLoadedRateId = 1; // set default
  useRateFromPriceFileTypes = [
    { id: 1, description: 'Price File' },
    { id: 2, description: 'Loaded File' }
  ];

  @HostListener('window:resize') onResize() {
    setTimeout(() => {
      this.setHeightWidths();
    }, 600); // wait for iPhone
  }

  constructor(
    private globalService: GlobalService,
    private maintenanceService: MaintenanceService,
    private jobService: JobService,
    private notiService: NotificationService,
    private estimatingService: EstimatingService,
    private authService: AuthService,
    private modalService: NgbModal,
    private poService: PoService) {

    this.calculateCustomSummary = this.calculateCustomSummary.bind(this);
    this.refreshTotals = this.refreshTotals.bind(this);
    this.calculateColour = this.calculateColour.bind(this);
    this.changeColour = this.changeColour.bind(this);
    this.copyJob = this.copyJob.bind(this);
    this.getPurchaseOrders = this.getPurchaseOrders.bind(this);
    this.onCostCentreSelectionChanged = this.onCostCentreSelectionChanged.bind(this);

    this.toolBarEditButtonOptions = {
      text: 'Edit Header',
      stylingMode: 'outlined',
      type: 'default',
      onClick: () => {
        this.openHeader();
      }
    };
    this.refreshButtonOptions = {
      icon: 'refresh',
      stylingMode: 'outlined',
      type: 'default',
      onClick: () => {
        this.getData(false);
      }
    };
    this.generateButtonOptions = {
      text: 'Generate Orders',
      stylingMode: 'outlined',
      type: 'default',
      onClick: () => {
        this.generateOrders();
      }
    };
    this.changeSupplierButtonOptions = {
      text: 'Change Vendor',
      stylingMode: 'outlined',
      type: 'default',
      onClick: () => {
        this.changeSupplier();
      }
    };
    this.recostButtonOptions = {
      text: 'Recost',
      stylingMode: 'outlined',
      type: 'default',
      onClick: () => {
        this.recostItems();
      }
    };
    this.loadRecipeButtonOptions = {
      text: 'Load Recipe',
      stylingMode: 'outlined',
      type: 'default',
      onClick: () => {
        this.loadFromRecipe();
      }
    };
    this.toolsMenuButtonOptions = {
      text: 'Tools',
      stylingMode: 'outlined',
      type: 'default',
      onClick: () => {
        this.toolsMenu();
      }
    };
    this.quoteItemsButtonOptions = {
      text: 'Items to Check',
      stylingMode: 'outlined',
      type: 'default',
      onClick: () => {
        this.itemsToCheck();
      }
    };
    this.loadButtonOptions = {
      icon: 'upload',
      stylingMode: 'outlined',
      type: 'default',
      onClick: () => {
        this.loadFromExcel();
      }
    };
    this.deleteButtonOptions = {
      icon: 'trash',
      stylingMode: 'outlined',
      type: 'default',
      onClick: () => {
        this.deleteAll();
      }
    };
    this.copyButtonOptions = {
      icon: 'copy',
      stylingMode: 'outlined',
      type: 'default',
      onClick: () => {
        this.copyJob();
      }
    };
    this.exportButtonOptions = {
      icon: 'download',
      stylingMode: 'outlined',
      type: 'default',
      onClick: () => {
        this.openExportGrid();
      }
    };
    this.showRolesOptions = {
      icon: 'user',
      stylingMode: 'outlined',
      type: 'default',
      onClick: () => {
        this.showJobRoles();
      }
    };
  }

  ngOnInit() {
    this.setHeightWidths();
    // this.subscribeToEmits();


    // get permission
    const poPerm = this.authService.areaPermissions.find(i => i.applicationArea === 'PurchaseOrders');
    if (this.authService.isAdminOrSuper() || poPerm?.permissionType === 'Admin') {
      this.canUseGlobalTools = true;
    }

    this.selectedCostCentres.push(this.globalService.selectedCostCentreId);
    this.loadData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  // subscribeToEmits() {
  //   this.subscriptions.push(
  //     this.globalService.innerHeightWidthChanged.subscribe(
  //       () => {
  //         this.setHeightWidths();
  //       }
  //     )
  //   );
  // }

  loadData() {
    this.subscriptions.push(
      this.poService.getOrdersData()
        .subscribe({
          next: () => {
            this.houseTypes = this.jobService.houseTypes;
            this.districts = this.maintenanceService.districts;
            this.vendors = this.maintenanceService.allVendors.filter(i => !i.hideFromOrders);
            this.phases = this.phases.concat(this.maintenanceService.phases);
            this.loadingData = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loadingData = false;
          }
        })
    );
  }

  onJobNumberChanged(jobNo: string) {
    if (this.jobNumber !== jobNo && this.jobService.currentJob) {
      this.jobNumber = jobNo;
      this.jobId = this.jobService.currentJob.id;

      this.ngOnDestroy(); // scrap any previous calls
      this.getData(false);
    } else {
      this.jobNumber = jobNo;
      this.costCentres = null;
    }
  }

  setHeightWidths() {
    this.loadingData = true;
    this.gridHeight = window.innerHeight - 160;
    this.gridWidth = window.innerWidth - 430;
    this.topToolbarWidth = window.innerWidth > 1530 ? window.innerWidth - 840 : window.innerWidth - 40;

    setTimeout(() => {
      this.loadingData = false;
    }, 500);
  }

  getData(useCache: boolean) {
    this.loading = true;
    this.isDatePast = false;
    this.costCentres = [];
    this.subscriptions.push(
      this.poService.getOrderHeaderData(this.jobId, useCache)
        .subscribe({
          next: (orderHeader) => {
            this.orderHeader = orderHeader;
            this.costCentres = this.estimatingService.costCentres;

            if (!orderHeader) {
              this.loading = false;
              // do we have perm to create?
              const poPerm = this.authService.areaPermissions.find(i => i.applicationArea === 'PurchaseOrders');
              if (this.authService.isAdminOrSuper() || poPerm?.permissionType === 'Write' || poPerm?.permissionType === 'Admin') {
                this.openHeader();
              }
            } else {
              let getRecipes = true;

              const baseDateString = JSON.stringify(this.orderHeader.baseDate).substring(0, 11);
              const newDateString = JSON.stringify(new Date()).substring(0, 11);
              if (baseDateString < newDateString) {
                this.isDatePast = true;
                if (this.globalService.ordersPermWrite) {
                  const unorderedLine = this.poService.orderLines.find(i => i.purchaseOrderId === null);
                  if (unorderedLine === undefined) {
                    // can auto update the base date to today
                    getRecipes = false;
                    this.updateHeaderBaseDate(true);
                  } else {
                    this.notiService.showWarning('Note: Date past and unordered lines exist. Edit Header to update the pricing base date',
                      null, { timeOut: 5000 });
                  }
                }
              }

              if (getRecipes) {
                this.getRecipes(useCache);
              }
            }
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  updateHeaderBaseDate(isChangedOnInit: boolean = false) {
    // this.resetBaseDatePopupVisible = false;
    this.subscriptions.push(
      this.poService.updateOrderHeader(this.jobId, { baseDate: new Date() })
        .subscribe({
          next: () => {
            this.notiService.showWarning(isChangedOnInit ? 'Note: Orders locked. Pricing base date updated to today' : 'Note: Pricing base date updated to today');
            this.isDatePast = false;
            this.orderHeader.baseDate = new Date();
            this.getRecipes(false);
          },
          error: (err) => {
            this.notiService.notify(err);
            this.getRecipes(false);
          }
        })
    );
  }

  getRecipes(useCache: boolean) {
    this.priceBookDescription = this.maintenanceService.districts.find(i => i.id === this.orderHeader.districtId)?.description;
    this.getAllRecipes(useCache, this.orderHeader.baseDate);
  }

  getAllRecipes(useCache: boolean, costDate: Date) {
    // read all JobCustomers records for this job
    this.subscriptions.push(
      this.estimatingService.getRecipesWithRates(useCache)
        .subscribe({
          next: () => {
            this.estimatingService.recipesAndItems = this.estimatingService.allRecipes;
            this.recipes = this.estimatingService.allRecipes;
            this.getPriceFile(costDate);
          },
          error: (err) => {
            this.notiService.notify(err);
            this.calcTotals();
          }
        })
    );
  }

  getPriceFile(costDate: Date) {
    // get the price file items
    this.subscriptions.push(
      this.estimatingService.getPriceFileItemsWithRates(costDate, this.orderHeader.districtId)
        .subscribe({
          next: (priceFileItems) => {
            // add the price file items to the recipe list
            priceFileItems.forEach(item => {
              const newItem = new Recipe();
              newItem.recipeCode = item.priceFileCode;
              newItem.description = item.description;
              newItem.rate = item.rate;
              newItem.unitOfMeasure = this.maintenanceService.unitOfMeasures.find(i => i.id === item.unitOfMeasureId)?.description;
              newItem.vendorItemDescription = item.vendorItemDescription;
              newItem.expiryDate = item.expiryDate;
              newItem.vendorId = item.vendorId;
              newItem.priceFileItemId = item.priceFileItemId;
              newItem.costCentreId = item.priceFileGroupId;
              newItem.masterGroupCostCentre
                = 'I' + ('00000' + item.groupOrderNumber.toString()).slice(-6) + ';' + item.masterGroupCostCentre;
              newItem.subGroupItemDesc = ('00000' + item.subGroupOrderNumber.toString()).slice(-6) + ';' + item.subGroupItemDesc;
              newItem.hasSizes = item.hasSizes;
              newItem.productCode = item.productCode;
              newItem.qtySizeControlId = item.qtySizeControlId;
              newItem.comment = item.comment;

              this.estimatingService.recipesAndItems = this.estimatingService.recipesAndItems.concat(newItem);
            });
            this.calcTotals();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.calcTotals();
          }
        })
    );
  }

  calcTotals() {
    this.grandTotal = 0;
    this.costCentres.forEach(costCentre => {
      this.poService.calcCostCentreTotal(costCentre);
      this.grandTotal += costCentre.total;
    });
    this.filteredCostCentres = this.costCentres.filter(i => i.isActive || i.orderedLineCount || i.unorderedLineCount);
    this.loading = false;
    this.isUpdating = false;
  }

  openHeader() {
    const isOrderHeaderExist = this.orderHeader ? true : false;
    const currentDate = this.orderHeader?.baseDate;
    const currentDistrictId = this.orderHeader?.districtId;
    const modalRef = this.modalService.open(OrderHeaderModalComponent, { windowClass: 'modal-edit' });
    modalRef.componentInstance.jobId = this.jobId;
    modalRef.componentInstance.orderHeader = this.orderHeader;

    modalRef.result.then((orderHeader: OrderHeader) => {
      if (orderHeader) {
        this.orderHeader = orderHeader;
        if (isOrderHeaderExist && this.orderHeader && (currentDate !== orderHeader.baseDate || currentDistrictId !== orderHeader.districtId)) {
          this.recostItems(); // Go2(null, true);
        } else {
          this.getData(true);
        }
      }
    });
  }

  onRowPrepared(e) {
    if (e.rowType === 'data') {
      if (e.data.id < 0) {
        // recipe line in green
        e.rowElement.style.backgroundColor = 'rgb(200, 250, 200)';
        e.rowElement.className = e.rowElement.className.replace('dx-row-alt', '');
      } else if (!e.data.unorderedLineCount && e.data.orderedLineCount && e.data.orderSentLineCount
        && e.data.orderedLineCount === e.data.orderSentLineCount) {
        e.rowElement.style.color = 'green';
        e.rowElement.style.fontWeight = 'bold';
        // e.rowElement.style.background = 'lightyellow';
      } else if (!e.data.unorderedLineCount && e.data.orderedLineCount) {
        e.rowElement.style.color = 'green';
        // e.rowElement.style.fontWeight = 'bold';
      } else if (!e.data.unorderedLineCount && !e.data.orderedLineCount) {
        const jobCostCentre = this.poService.jobCostCentres.find(i => i.costCentreId === e.data.id);
        if (jobCostCentre?.colourId === -1) {
          e.rowElement.style.textDecoration = 'line-through';
        }
        e.rowElement.style.color = 'rgb(128, 128, 128, .9)';
      } else {
        e.rowElement.style.fontWeight = 'bold';
      }
    }
  }

  generateOrders() {
    const baseDateString = JSON.stringify(this.orderHeader.baseDate).substring(0, 11);
    const newDateString = JSON.stringify(new Date()).substring(0, 11);
    if (baseDateString < newDateString) {
      const unorderedLine = this.poService.orderLines.find(i => i.purchaseOrderId === null);
      if (unorderedLine !== undefined) {
        this.notiService.showWarning('Note: Date past and unordered lines exist. Edit Header to update the pricing base date',
          null, { timeOut: 5000 });
      }
    }

    const modalRef = this.modalService.open(CostCentreSelectComponent, { windowClass: 'modal-edit2' });
    modalRef.componentInstance.currentCostCentreId = this.selectedCostCentres[0];
    modalRef.componentInstance.titleText = 'Generate Orders';

    modalRef.result.then(result => {
      this.generateOrdersGo(result.selectedCostCentreIds, result.phaseId, result.constructionStageId);
    });
  }

  generateOrdersGo(selectedCostCentres: number[], phaseId: number, constructionStageId: number) {
    if (selectedCostCentres?.length) {
      // check vendors are valid
      const dateNow = new Date();
      const dateNowString = formatDate(dateNow, 'yyyy-MM-dd');
      const vendorIds: number[] = [];

      selectedCostCentres.forEach(costCentreId => {
        const costCentreLines = this.poService.orderLines.filter(i => i.costCentreId === costCentreId);

        costCentreLines.forEach(costCentreLine => {
          if (!vendorIds.find(i => i === costCentreLine.vendorId)) {
            vendorIds.push(costCentreLine.vendorId);
          }
        });
      });

      vendorIds.forEach(vendorId => {
        const vendor = this.vendors.find(i => i.id === vendorId);
        if (vendor?.incomeProtectionExpiry && vendor.incomeProtectionExpiry <= dateNowString) {
          this.notiService.showWarning('Vendor: ' + vendor.vendorName + ' has expired Income Protection Insurance.');
        }
        if (vendor?.publicLiabilityExpiry && vendor.publicLiabilityExpiry <= dateNowString) {
          this.notiService.showWarning('Vendor: ' + vendor.vendorName + ' has expired Public Liability.');
        }
        if (vendor?.workCoverExpiry && vendor.workCoverExpiry <= dateNowString) {
          this.notiService.showWarning('Vendor:' + vendor.vendorName + ' has expired WorkCover.');
        }
      });

      this.isUpdating = true;
      this.subscriptions.push(
        this.poService.generateOrders(this.orderHeader.jobId, selectedCostCentres, phaseId, constructionStageId)
          .subscribe({
            next: (updatedOrderLines) => {
              updatedOrderLines.forEach(updatedOrderLine => {
                const existingOrderLine = this.poService.orderLines.find(i => i.id === updatedOrderLine.id);
                if (existingOrderLine) {
                  existingOrderLine.purchaseOrderId = updatedOrderLine.purchaseOrderId;
                }
              });

              this.getPurchaseOrders(false);
              this.refreshOrderLines();
            },
            error: (err) => {
              this.notiService.notify(err);
              this.isUpdating = false;
            }
          })
      );
    }
  }

  getPurchaseOrders(recalc: boolean) {
    this.loading = true;
    this.subscriptions.push(
      this.poService.getPurchaseOrdersForJob(this.orderHeader.jobId)
        .subscribe({
          next: () => {
            this.loading = false;
            this.isUpdating = false;

            if (recalc) {
              this.isRefresh = !this.isRefresh; // refresh components
              this.calcTotals();
              this.costCentreGrid?.instance?.refresh(false);
            }
          },
          error: (err) => {
            this.notiService.notify(err);
            this.isUpdating = false;
            this.loading = false;
          }
        })
    );
  }

  itemsToCheck() {
    // we will have another pop-up to show/update items to check - e.g. for quoting
    this.notiService.showInfo('Option not functional...');
  }

  loadFromRecipe() {
    this.loadingExcelFile = false;
    this.loadRecipeQuantity = 1;
    this.recipeLoadPopupVisible = true;
  }

  loadFromRecipeGo() {
    // run load
    this.loadingExcelFile = true;

    this.subscriptions.push(
      this.poService.loadOrderLinesFromRecipe(this.orderHeader.jobId, this.recipeId,
        this.stopIfExistingId === 1 ? true : false, this.loadRecipeQuantity)
        .subscribe({
          next: () => {
            this.refreshOrderLines();
          }, error: (err) => {
            this.notiService.notify(err);
            this.loadingExcelFile = false;
          }
        })
    );
  }

  loadFromExcel() {
    // load from Excel
    this.fileValue = [];
    this.loadingExcelFile = false;
    this.excelLoadPopupVisible = true;
  }

  loadFromExcelGo() {
    // run load
    this.loadingExcelFile = true;
    const formData: FormData = new FormData();
    formData.append('image', this.fileValue[0], this.fileValue[0].name);

    this.subscriptions.push(
      this.poService
        .loadOrderLinesFromExcel(formData, this.orderHeader.id,
          this.loadDescriptionTypeId === 1 ? false : true,
          this.stopIfExistingId === 1 ? true : false,
          this.useLoadedRateId === 1 ? false : true)
        .subscribe({
          next: () => {
            this.refreshOrderLines();
          }, error: (err) => {
            this.notiService.notify(err);
            this.fileValue = [];
            this.loadingExcelFile = false;
          }
        })
    );
  }

  deleteAll() {
    // mark all unorderd lines as to be checked
    this.deleteAllPopupVisible = true;
    this.jobNumberCheck = '';
  }

  deleteAllGo() {
    if (this.jobNumberCheck !== this.jobNumber) {
      this.notiService.showInfo('Job number not correct');
    } else {
      this.deleteAllPopupVisible = false;
      this.loading = true;

      this.subscriptions.push(
        this.poService.deleteAllItems(this.orderHeader.jobId, null).subscribe({
          next: () => {
            this.refreshOrderLines();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
      );
    }
  }

  refreshOrderLines() {
    this.subscriptions.push(
      this.poService.getOrderLines(this.orderHeader.jobId, null)
        .subscribe({
          next: () => {
            this.loadingExcelFile = false;
            this.excelLoadPopupVisible = false;
            this.recipeLoadPopupVisible = false;
            this.recostPopupVisible = false;
            this.changeVendorPopupVisible = false;
            this.isRefresh = !this.isRefresh; // refresh components
            this.calcTotals();
            this.costCentreGrid?.instance?.refresh(true);
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
            this.isUpdating = false;
          }
        })
    );
  }

  recostItems() {
    this.recostPopupVisible = true;
    this.recostLockedLines = false;
    this.generateOrdersForId = GenerateOrdersEnum.AllCentres;
    this.isUpdating = false;
  }

  recostItemsGo() {
    this.recostPopupVisible = false;
    if (this.generateOrdersForId === GenerateOrdersEnum.ThisCostCentre && (!this.selectedCostCentres || !this.selectedCostCentres.length)) {
      this.notiService.showInfo('No Cost Centre selected');
    } else {
      this.recostItemsGo2(this.generateOrdersForId === GenerateOrdersEnum.ThisCostCentre
        ? this.selectedCostCentres && this.selectedCostCentres.length
          ? this.selectedCostCentres[0] : null : null, true);
    }
  }

  recostItemsGo2(costCentreId: number, refreshAll: boolean) {
    if (refreshAll) {
      this.loading = true;
    }
    this.isUpdating = true;
    this.subscriptions.push(
      this.poService.recostItems(this.orderHeader.jobId, costCentreId, this.recostLockedLines)
        .subscribe({
          next: () => {
            this.isUpdating = false;
            if (refreshAll) {
              this.getData(true);
            } else {
              this.refreshOrderLines();
            }
          },
          error: (err) => {
            this.notiService.notify(err);
            this.isUpdating = false;
            this.loading = false;
          }
        })
    );
  }

  changeSupplier() {
    this.changeVendorPopupVisible = true;
    this.generateOrdersForId = GenerateOrdersEnum.ThisCostCentre;
    this.isUpdating = false;
  }

  changeSupplierGo() {
    if (this.generateOrdersForId === GenerateOrdersEnum.ThisCostCentre && (!this.selectedCostCentres || !this.selectedCostCentres.length)) {
      this.notiService.showInfo('No Cost Centre selected');
    } else if (this.generateOrdersForId !== GenerateOrdersEnum.ThisCostCentre && !this.oldVendorId) {
      this.notiService.showInfo('Must specify a vendor to change for all cost centres');
    } else {
      this.isUpdating = true;
      this.subscriptions.push(
        this.poService.changeVendor(this.orderHeader.jobId, this.generateOrdersForId === GenerateOrdersEnum.ThisCostCentre
          ? this.selectedCostCentres && this.selectedCostCentres.length
            ? this.selectedCostCentres[0] : null : null, this.oldVendorId, this.newVendorId, this.isZeroRates)
          .subscribe({
            next: (orderLines) => {
              if (!orderLines?.length) {
                this.notiService.showInfo('No lines found to change');
              } else {
                this.notiService.showInfo('Changed ' + orderLines?.length + ' line(s)');
              }
              this.refreshOrderLines();
            },
            error: (err) => {
              this.notiService.notify(err);
              this.isUpdating = false;
            }
          })
      );
    }
  }

  openExportGrid() {
    const modalRef = this.modalService.open(OrderExportComponent, { windowClass: 'modal-fullScreen' });

    modalRef.result.then(() => {
    });
  }

  refreshTotals() {
    this.grandTotal = 0;
    this.costCentres.forEach(costCentre => {
      this.poService.calcCostCentreTotal(costCentre);
      this.grandTotal += costCentre.total;
    });
    this.costCentreGrid.instance.refresh(true);
  }

  calculateCustomSummary(options) {
    if (options.name === 'TotalLine') {
      if (options.summaryProcess === 'start') {
        options.totalValue = this.grandTotal;
      }
    }
  }

  getGroupTitle(cellInfo) {
    return cellInfo.data.key.split(';')[1];
  }

  onSelectionChanged(e, event) {
    if (event.selectedRowKeys.length > 0) {
      this.recipeId = event.selectedRowsData[0].id;
      e.component.close();
    }
  }

  downloadSample() {
    this.notiService.showInfo('Please wait for download to complete');
    this.subscriptions.push(
      this.poService.getSampleExcelUpload(10).subscribe({
        next: (res) => {
          saveAs(this.globalService.getBlobFromBase64(res.pdfReport, AttachmentTypeEnum.XLS), res.pdfReportName);
        }, error: (err) => {
          this.notiService.notify(err);
        }
      })
    );
  }

  calculateColour(data) {
    const jobCostCentre = this.poService.jobCostCentres.find(i => i.costCentreId === data.id);
    if (jobCostCentre) {
      if (!jobCostCentre.comment) {
        jobCostCentre.comment = '';
      }
      if (!jobCostCentre.colourId) {
        jobCostCentre.colourId = 0;
      }
      return jobCostCentre;
    } else {
      return { id: 0, jobId: 0, costCentreId: data.id, colourId: 0, isLocked: false, comment: '' };
    }
  }

  changeColour(e: JobCostCentre) {
    const currentCostCentre = this.costCentres.find(i => i.id === e.costCentreId);
    const hasOrderLines = this.poService.orderLines.find(i => i.costCentreId === e.costCentreId);
    const jobCostCentre = this.poService.jobCostCentres.find(i => i.costCentreId === e.costCentreId);

    const modalRef = this.modalService.open(ColourNoteComponent, { windowClass: 'modal-lg' });
    modalRef.componentInstance.comment = jobCostCentre?.comment;
    modalRef.componentInstance.colourId = jobCostCentre?.colourId;
    modalRef.componentInstance.costCentreDescription = currentCostCentre.description;
    modalRef.componentInstance.hasOrderLines = hasOrderLines !== undefined ? true : false;

    modalRef.result.then(jobComment => {
      if (!e.id) {
        this.subscriptions.push(
          this.poService.addJobCostCentre({
            jobId: this.jobId,
            costCentreId: e.costCentreId,
            colourId: jobComment.colourId,
            isLocked: false,
            comment: jobComment.comment
          })
            .subscribe({
              next: (newJobCostCentre) => {
                this.poService.jobCostCentres.push(newJobCostCentre);
                this.costCentreGrid.instance.refresh(false);
              },
              error: (err) => {
                this.notiService.notify(err);
              }
            })
        );
      } else {
        // update it
        this.subscriptions.push(
          this.poService.updateJobCostCentre(e.id,
            {
              colourId: jobComment.colourId,
              comment: jobComment.comment
            })
            .subscribe({
              next: () => {
                this.costCentreGrid.instance.refresh(false);
              },
              error: (err) => {
                this.notiService.notify(err);
              }
            })
        );
        // update assume works
        const currentJobCostCentre = this.poService.jobCostCentres.find(i => i.id === e.id);
        currentJobCostCentre.colourId = jobComment.colourId;
        currentJobCostCentre.comment = jobComment.comment;
      }
    });
  }

  copyJob() {
    const modalRef = this.modalService.open(CopyJobComponent, { windowClass: 'modal-1000' });

    modalRef.result.then(result => {
      this.isUpdating = true;
      this.subscriptions.push(
        this.poService.copyJob(result.fromJobId, this.orderHeader.id, result.zeroQuantities, result.selectedIds, result.ignoreExistingCostCentres)
          .subscribe({
            next: () => {
              this.isUpdating = false;
              this.getData(true);
            },
            error: (err) => {
              this.notiService.notify(err);
              this.isUpdating = false;
            }
          })
      );
    });
  }

  showJobRoles() {
    // use modal to set the users to the roles
    const modalRef = this.modalService.open(JobRolesComponent, { windowClass: 'modal-edit' });
    modalRef.componentInstance.jobNumber = this.jobNumber;

    modalRef.result.then(() => { });
  }

  onCostCentreSelectionChanged(e) {
    this.selectedCostCentres = e.selectedRowKeys;
    this.globalService.selectedCostCentreId = e.selectedRowKeys[0];
  }

  toolsMenu() {
    // show tools menu
    const modalRef = this.modalService.open(OrderToolsMenuComponent, { windowClass: 'modal-edit3' });
    modalRef.componentInstance.jobNumber = this.jobNumber;

    modalRef.result.then(() => {
      this.getData(false);
    });
  }
}
