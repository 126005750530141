import { Injectable } from '@angular/core';

export class HelpDictionary {
  dataField: string;
  title: string;
  helpText: string;
}

const helpDictionary: HelpDictionary[] = [{
  dataField: 'extraIncomeIncGST',
  title: 'Extra Income Inc.GST',
  helpText: 'User definable field to allow entry of extra income, e.g. rebates, to correctly display the margin for the job. This is only used in this margin calculation screen.',
},
{
  dataField: 'contractPriceIncGST',
  title: 'Forecast Price Inc.GST',
  helpText: 'If the contract is locked this is equal to the contract price.\n' +
    'If the contract is NOT locked this is the sales price however this can be overridden via the WIP or the Tracking -> Forecast.',
},
{
  dataField: 'jobCostExcGST',
  title: 'Job Cost Ex.GST',
  helpText: 'If Orders are NOT locked this is the greater of the estimated total or the manual job cost entered into the Forecast.\n'
    + 'If the orders ARE locked then this is the total of the Orders however this will be affected by orders with accrual stopped'
    + ' where the total for that PO is then the total of the invoices entered against that purchase order.',
},
{
  dataField: 'jobCostExcGST-WIP',
  title: 'Job Cost Ex.GST',
  helpText: 'If Orders are NOT locked this is the greater of the estimated total or the manual job cost entered into the Forecast.\n'
    + 'If the orders ARE locked then this is the total of the Orders however this will be affected by orders with accrual stopped'
    + ' where the total for that PO is then the total of the invoices entered against that purchase order.\n\n'
    + "If the WIP report uses 'Task complete to accrue order total' (as set in Tracking Control) AND the Cost Centre flag:"
    + " 'Exclude From WIP Until Completed' is set then that Cost Centre's cost will NOT be included in the WIP calculation unless the job has reached practical completion. E.g., for developer cash-backs or marketing costs",
},
{
  dataField: 'costAdjustment',
  title: 'Cost Adjustment',
  helpText: 'If you are using the cost adjustment method, a positive adjustment means that you will need to decrease cost via journal.',
},
{
  dataField: 'plAdjustment',
  title: 'Income Adjustment',
  helpText: 'If you are using the income adjustment method, a positive adjustment means that you will need to increase income via journal.',
}
];




@Injectable({
  providedIn: 'root'
})
export class HelpService {
  getHelpDictionary() {
    return helpDictionary;
  }
}
