
import { Component, Input, Output,  EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { SelectMergeTemplateForm } from '../../../dtos/invoice';
import CustomStore from 'devextreme/data/custom_store';
import { DocumentService } from '../../../services/felixApi/document.service';
import { DocumentFolder } from '../../../dtos/document-folder';
import { GlobalService } from '../../../services/global.service';


@Component({
  selector: 'js-select-merge-template',
  templateUrl: './select-merge-template.component.html',
  styleUrls: ['./select-merge-template.component.scss']
})
export class SelectMergeTemplateComponent implements OnInit, OnDestroy {
  @Input() isSharePoint: boolean;
  @Input() claimDocumentFolder: DocumentFolder;

  @Output() templateId: EventEmitter<string> =
  new EventEmitter<string>();

  subscriptions: Subscription[] = [];
  loading = true;
  selectMergeTemplateForm: SelectMergeTemplateForm;
  selectedTemplateId: string;
  selectedTemplateName: string;
  dataSource2: CustomStore;
  autoDownloadDoc: boolean = true;
  generatePDF: boolean = true;
  claimTemplateFiles: DocumentFolder[];
  selectTemplateButtonOptions = {
    text: 'Select',
    type: 'default',
    stylingMode: 'contained',
    useSubmitBehavior: true
  };


  constructor(
    private _activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private documentService: DocumentService,
    private globalService: GlobalService,
    
  ) {  
    this.selectionChanged = this.selectionChanged.bind(this);
    this.createPDFChanged = this.createPDFChanged.bind(this);
    this.autoDownloadChanged = this.autoDownloadChanged.bind(this);
  
  }

  ngOnInit() {
    this.loading = false;
    this.selectMergeTemplateForm = new SelectMergeTemplateForm;
    this.getFiles();
    this.loading = false;
    
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  cancel() {
    this._activeModal.dismiss();
  }

  selectTemplate() {
    this._activeModal.close({selectedTemplateId: this.selectedTemplateId, selectedTemplateName: this.selectedTemplateName, autoDownloadDoc: this.autoDownloadDoc, generatePDF: this.generatePDF});
  }

  selectionChanged(event) {
    this.selectedTemplateId = event.addedItems[0].id;
    this.selectedTemplateName = event.addedItems[0].name;
  }

  createPDFChanged(event){
    this.generatePDF = event.value;
  }

  autoDownloadChanged(event){
    this.autoDownloadDoc = event.value;
  }

  getFiles() {
    this.dataSource2 = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.documentService.getSharePointDocTemplates(this.claimDocumentFolder.description, this.isSharePoint).subscribe({
            next: (res) => {
              this.claimTemplateFiles = res;
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    })
    ;
  }

}
