import { NotificationService } from './../../../services/notification.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { GridService } from '../../../services/grid.service';
import { Subscription } from 'rxjs';
import { JobService } from '../../../services/felixApi/job.service';
import { MaintenanceService } from '../../../services/felixApi/maintenance.service';
import { VarianceCode } from '../../../dtos/variance-code';
import { PoService } from '../../../services/felixApi/po.service';

@Component({
  selector: 'js-order-extra-fix',
  templateUrl: './order-extra-fix.component.html',
  styleUrls: ['./order-extra-fix.component.scss']
})
export class OrderExtraFixComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  loading = true;
  jobId: number;
  varianceCodeId: number;
  varianceCodes: VarianceCode[];

  constructor(
    protected gridService: GridService,
    private notiService: NotificationService,
    private jobService: JobService,
    private maintenanceService: MaintenanceService,
    private poService: PoService
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  loadData() {
    this.subscriptions.push(
      this.maintenanceService.getExtraCodes(true)
        .subscribe({
          next:
            (varianceCodes) => {
              this.varianceCodes = varianceCodes;
              this.loading = false;
            },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  onJobNumberChanged(jobNo: string) {
    if (jobNo && this.jobService.currentJob) {
      this.jobId = this.jobService.currentJob.id;
    } else {
      this.jobId = null;
    }
  }

  runFix(allJobs: boolean) {
    if (this.varianceCodeId) {
      this.loading = true;
      this.subscriptions.push(
        this.poService.moveBudgetsToCorrectPOs(allJobs ? null : this.jobId, this.varianceCodeId).subscribe(
          () => {
            this.notiService.showSuccess('Order Extra Fix', 'Fix ran successfully');
            this.loading = false;
          },
          (error) => {
            this.notiService.notify(error);
            this.loading = false;
          }
        )
      );
    } else {
      this.notiService.showInfo('Please select a variance code');
    }
  }
}
